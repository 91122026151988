import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";
import { APP_ROUTES } from "@constants/urls.constants";
import { Animation } from "@presentational/atoms/animate/Animation";
import { GoldenButton } from "@presentational/atoms/button/common/GoldenButton";
import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { Icon } from "@presentational/atoms/icon/Icon";
import { Color } from "@presentational/themes/colors";
import { formatDate } from "@utils/helpers/datetime.helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useBluelyraPlayback } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraPlayback";
import { useBluelyraVideo } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraVideo";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import { useGetCurrentPlaylistById } from "@business-layer/business-logic/lib/bluelyra/hooks/useGetCurrentPlaylistById";
import { Loop } from "@business-layer/business-logic/lib/bluelyra/context/type";
import ReactPlayer from "react-player";

function formatSeconds(seconds: number) {
  const hrs = Math.floor(seconds / 3600); // Tính giờ
  const mins = Math.floor((seconds % 3600) / 60); // Tính phút
  const secs = Math.floor(seconds % 60); // Tính giây

  // Tạo chuỗi định dạng
  if (hrs > 0) {
    return `${hrs}:${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  } else {
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  }
}

export const BluelyraPlaylistDetail = () => {
  const navigate = useNavigate();
  const pathNameSplited = useLocation().pathname.split("/");
  const playlistId = pathNameSplited[pathNameSplited.length - 1];
  const currentPlaylist = useGetCurrentPlaylistById(playlistId);
  const { isPlaying, loop, volume, isShuffle, played, seekTo } =
    useGetContextValue("bluelyraPlayback");
  const { currentVideo } = useGetContextValue("bluelyraVideo");
  const {
    updateIsPlaying,
    toggleLoop,
    updateVolume,
    updatePlayed,
    updateIsShuffle,
    updateSeekTo,
  } = useBluelyraPlayback();
  const { updateCurrentVideo } = useBluelyraVideo();
  const changeVolumeContainerRef = useRef<HTMLDivElement>(null);
  const changePlayedContainerRef = useRef<HTMLDivElement>(null);
  const volumeBtnRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);
  const { value: isHoveringVolume, setTrue, setFalse } = useBoolean(false);
  const currentVideoDuration = useMemo(
    () => playerRef.current?.getDuration() || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playerRef.current]
  );

  const handleVolumeMouseMove = useCallback((event: any) => {
    if (changeVolumeContainerRef.current && volumeBtnRef.current) {
      const { left, width: containerWidth } =
        changeVolumeContainerRef.current.getBoundingClientRect();
      const { width } = volumeBtnRef.current.getBoundingClientRect();
      const offsetX = event.clientX - left;
      const newVolume = Math.min(
        Math.max((offsetX - width / 2) / containerWidth, 0),
        1
      );
      updateVolume(newVolume);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleVolumeMouseDown = (event: any) => {
    document.addEventListener("mousemove", handleVolumeMouseMove);
    document.addEventListener("mouseup", handleVolumeMouseUp);
  };
  const handleVolumeMouseUp = (event: any) => {
    document.removeEventListener("mousemove", handleVolumeMouseMove);
    document.removeEventListener("mouseup", handleVolumeMouseUp);
  };

  const handlePlayedMouseMove = useCallback((event: any) => {
    if (changePlayedContainerRef.current) {
      const { left, width: containerWidth } =
        changePlayedContainerRef.current.getBoundingClientRect();
      const offsetX = event.clientX - left;
      const newPlayed = Math.min(
        Math.max((offsetX - 15 / 2) / containerWidth, 0),
        1
      );
      updatePlayed(newPlayed);
      updateSeekTo(newPlayed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePlayedMouseDown = (event: any) => {
    document.addEventListener("mousemove", handlePlayedMouseMove);
    document.addEventListener("mouseup", handlePlayedMouseUp);
  };
  const handlePlayedMouseUp = (event: any) => {
    document.removeEventListener("mousemove", handlePlayedMouseMove);
    document.removeEventListener("mouseup", handlePlayedMouseUp);
  };

  useEffect(() => {
    if (seekTo && playerRef.current) {
      playerRef.current.seekTo(seekTo - 0.00001);
    }
  }, [seekTo]);

  return currentPlaylist ? (
    <div className="grid grid-cols-[400px,1fr] gap-10 h-full w-full">
      <div className="bs-background bs-background--rounded h-full">
        <div className="p-10 text-white">
          <Animation
            as="div"
            animateType="fade__go-up"
            key="bluelyra-playlist-detail-1"
            className="relative overflow-hidden"
          >
            {currentVideo ? (
              <>
                <div className="rounded-50 overflow-hidden">
                  <ReactPlayer
                    ref={playerRef}
                    url={`https://www.youtube.com/watch?v=${currentVideo.youtubeVideoId}`}
                    playing={isPlaying}
                    volume={volume}
                    stopOnUnmount={true}
                    progressInterval={1000}
                    width={400}
                    height={225}
                    muted={true}
                    onPause={() => updateIsPlaying(false)}
                    onPlay={() => updateIsPlaying(true)}
                  />
                </div>
                <div className="flex flex-row items-center gap-5 mt-15 px-25">
                  <p className="text-small">
                    {formatSeconds(played * currentVideoDuration)} /{" "}
                    {formatSeconds(currentVideoDuration)}
                  </p>

                  <div
                    className="relative flex-grow"
                    ref={changePlayedContainerRef}
                  >
                    <hr className="w-full bg-white h-[3px]" />
                    <div
                      onMouseDown={handlePlayedMouseDown}
                      className="w-15 h-15 rounded-full bg-white absolute top-1/2 -translate-y-1/2 cursor-grab"
                      style={{ left: `${played * 100}%` }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <img
                  src={currentPlaylist?.image.format.standard.url}
                  alt="Thumbnail"
                  className="w-full aspect-video h-fit relative z-0 rounded-50 border-[1px] border-solid border-dark"
                />
                <div className="bs-absolute-full bg-gradient-to-t rounded-50 from-dark z-10 px-50 pb-15 flex flex-col justify-end">
                  <p className="text-h3 text-center line-clamp-3 select-none">
                    {currentPlaylist.name}
                  </p>
                </div>
              </>
            )}
          </Animation>
          <Animation
            as="div"
            animateType="fade__go-up"
            key="bluelyra-playlist-detail-2"
            className="flex flex-col gap-15 max-w-500 mt-15 px-20"
          >
            <div className="mb-20 flex flex-row flex-wrap gap-10">
              <GoldenButton
                onClick={() =>
                  navigate(
                    `${APP_ROUTES.BluelyraPlaylist}/${currentPlaylist.id}`
                  )
                }
              >
                <Icon icon="Youtube" color={Color.Dark} />
                {/* TODO: ADDLANG */}
                <p>Thêm video</p>
              </GoldenButton>
              <GoldenButton
                onClick={() => navigate(APP_ROUTES.BluelyraPlaylistStore)}
              >
                <Icon icon="Bag2" color={Color.Dark} />
                {/* TODO: ADDLANG */}
                <p>Cửa hàng playlist</p>
              </GoldenButton>
            </div>
            <div className="bs-flex-row-start gap-5">
              <Icon icon="Clock" width={20} height={20} />
              {/* TODO: ADDLANG */}
              <p className="text-standard">
                Được tạo vào ngày{" "}
                {formatDate(currentPlaylist.createdAt).dateMonthYear}
              </p>
            </div>
            <div className="bs-flex-row-start gap-5">
              {/* TODO: ADDLANG */}
              {currentPlaylist.isPublic ? (
                <>
                  <Icon icon="Eye" width={20} height={20} />
                  <p className="text-standard">Công khai</p>
                </>
              ) : (
                <>
                  <Icon
                    icon="Lock"
                    width={20}
                    height={20}
                    className="relative bottom-[2px]"
                  />
                  <p className="text-standard">Riêng tư</p>
                </>
              )}
            </div>
            {/* <div className="bs-flex-row-start gap-5">
            <Icon icon="Clock" />
            <p className="text-standard">{currentPlaylist.} lượt phát</p>
          </div> */}
            {currentPlaylist.isPublic ? (
              <div className="bs-flex-row-start gap-5">
                <Icon
                  icon="Import"
                  width={20}
                  height={20}
                  className="relative bottom-[2px]"
                />
                {/* TODO: ADDLANG */}
                <p className="text-standard">
                  {currentPlaylist.downloaded} lượt tải xuống
                </p>
              </div>
            ) : null}
            {/* TODO: ADDLANG */}
            <hr />
            <p className="text-standard font-bold">Danh sách phát của</p>
            <Link
              to={`${APP_ROUTES.Account}/${currentPlaylist.owner.id}`}
              className="bs-flex-row-start gap-15"
            >
              <img
                src={currentPlaylist.owner.avatar}
                alt="Avatar"
                className="w-45 h-45 object-cover rounded-full"
              />
              <div>
                <p className="text-standard font-bold">
                  {currentPlaylist.owner.username}
                </p>
              </div>
            </Link>
          </Animation>
        </div>
      </div>
      <div className="bs-background bs-background--rounded h-full py-15 px-20 overflow-y-auto">
        <motion.div
          layout={true}
          className="mx-auto rounded-full py-15 px-25 bg-secondary w-fit flex gap-20 items-center justify-center"
        >
          <div
            className="flex gap-5 items-center"
            onMouseLeave={() => isHoveringVolume && setFalse()}
          >
            <IconButton
              icon="VolumeHigh"
              onMouseEnter={() => !isHoveringVolume && setTrue()}
            />
            <AnimatePresence mode="wait">
              {isHoveringVolume ? (
                <motion.div
                  as="div"
                  key={"playlist-detail__volume-changer"}
                  layout={true}
                  initial={{
                    opacity: 0,
                    width: 0,
                    display: "none",
                  }}
                  animate={{
                    opacity: 1,
                    width: "70px",
                    display: "block",
                  }}
                  exit={{
                    opacity: 0,
                    width: 0,
                    display: "none",
                  }}
                  className="relative w-70"
                  ref={changeVolumeContainerRef}
                >
                  <hr className="w-full bg-white h-[3px]" />
                  <div
                    onMouseDown={handleVolumeMouseDown}
                    ref={volumeBtnRef}
                    className="w-15 h-15 rounded-full bg-white absolute top-1/2 -translate-y-1/2 cursor-grab"
                    style={{ left: `${volume * 100}%` }}
                  />
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>
          {currentVideo && isPlaying ? (
            <p className="text-small font-bold text-white line-clamp-1 max-w-250 select-none pointer-events-none relative bottom-[-1px]">
              {/* TODO: ADDLANG */}
              Đang phát: {currentVideo.name}
            </p>
          ) : null}
          <IconButton
            icon="Shuffle"
            color={isShuffle ? Color.Sky : Color.White}
            onClick={() => updateIsShuffle(!isShuffle)}
          />
          <IconButton
            icon={loop === Loop.Single ? "RepeateOne" : "RepeateMusic"}
            color={loop !== Loop.None ? Color.Sky : Color.White}
            onClick={toggleLoop}
          />
        </motion.div>
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-15 mt-20">
          {currentPlaylist.videos.map((v) => {
            const isCurrentPlaying = isPlaying && v.id === currentVideo?.id;
            return (
              <button
                key={v.id}
                type="button"
                className="text-white text-left flex flex-col gap-5 relative bs-hover-show-child__parent"
                onClick={() => {
                  if (v.id !== currentVideo?.id) {
                    updateCurrentVideo(v);
                    updateIsPlaying(true);
                  } else {
                    updateIsPlaying(!isPlaying);
                  }
                }}
              >
                <img
                  src={v.thumbnail}
                  alt="Thumbnail not found"
                  className="rounded-20 w-full h-fit aspect-[4/3] object-cover"
                />
                <p className="text-standard font-regular line-clamp-3">
                  {v.name}
                </p>
                <small className="font-regular">{v.channel}</small>
                <div
                  className="rounded-20 w-full h-fit aspect-[4/3] bg-dark bg-opacity-60 absolute top-0 left-0 grid place-items-center bs-hover-show-child__child"
                  style={
                    isCurrentPlaying
                      ? { opacity: 1, visibility: "visible" }
                      : {}
                  }
                >
                  <div className="text-[30px] font-bold w-50 h-50 rounded-full text-gold border-2 border-solid border-gold grid place-items-center">
                    <Icon
                      icon={isCurrentPlaying ? "Pause" : "Play"}
                      color={Color.Gold}
                    />
                  </div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  ) : null;
};
