import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 8.33994V15.6599C22.5 17.1599 20.87 18.0999 19.57 17.3499L16.4 15.5299L13.23 13.7C13.03 13.58 12.87 13.45 12.74 13.29V10.73C12.87 10.57 13.03 10.44 13.23 10.32L16.4 8.48993L19.57 6.66996C20.87 5.89996 22.5 6.83994 22.5 8.33994Z"
        stroke={color || "#FFFFFF"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.74 8.33994V15.6599C12.74 17.1599 11.11 18.0999 9.80999 17.3499L6.64001 15.5299L3.47 13.7C2.17 12.95 2.17 11.08 3.47 10.32L6.64001 8.48993L9.80999 6.66996C11.11 5.89996 12.74 6.83994 12.74 8.33994Z"
        stroke={color || "#FFFFFF"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
