import React from "react";
import { APP_REDUCERS } from "../configs";
import QueryProvider from "./ReactQueryProvider";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";

const APP_STORE = configureStore({
  reducer: APP_REDUCERS,
});

type BusinessLogicProviderType = {
  children: React.ReactNode;
};
export const BusinessLogicProvider: React.FC<BusinessLogicProviderType> = ({
  children,
}) => {
  return (
    <QueryProvider>
      <Provider store={APP_STORE}>{children}</Provider>
    </QueryProvider>
  );
};
