import { ValidationTranslationsType } from "@constants/locale.constants";
import * as y from "yup";

export const loginFormSchema = y.object({
  email: y
    .string()
    .typeError("common.email.typeError" as ValidationTranslationsType)
    .required("common.email.required" as ValidationTranslationsType)
    .email("common.email.typeError" as ValidationTranslationsType)
    .transform((value) => value.trim()),
  password: y
    .string()
    .typeError("common.password.typeError" as ValidationTranslationsType)
    .min(6, "common.password.min" as ValidationTranslationsType)
    .transform((value) => value.trim()),
});

export const registerFormSchema = y.object({
  email: y
    .string()
    .typeError("common.email.typeError" as ValidationTranslationsType)
    .required("common.email.required" as ValidationTranslationsType)
    .email("common.email.typeError" as ValidationTranslationsType)
    .transform((value) => value.trim()),
  password: y
    .string()
    .typeError("common.password.typeError" as ValidationTranslationsType)
    .min(6, "common.password.min" as ValidationTranslationsType)
    .transform((value) => value.trim()),
  username: y
    .string()
    .typeError("common.typeError" as ValidationTranslationsType)
    .required("register.username.required" as ValidationTranslationsType)
    .max(50, "register.username.max" as ValidationTranslationsType)
    .transform((value) => value.trim()),
});
