import "./presentational/styles/global.scss";
import MainRouter from "./routes";
import { useEffect, useState } from "react";
import {
  enableDdosFirewallMiddleware,
  useRefreshSessionInterceptor,
} from "@business-layer/services";
import { AnimatePresence } from "framer-motion";
import { FallbackLoader } from "@presentational/atoms/loader/FallbackLoader";

const INTRO_DURATION = 1000;

function App() {
  useRefreshSessionInterceptor();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    enableDdosFirewallMiddleware();
    const timer = setTimeout(() => setIsReady(true), INTRO_DURATION);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AnimatePresence mode="wait">
        {isReady ? null : <FallbackLoader key="FallbackLoader" />}
      </AnimatePresence>
      <MainRouter key="MainRouter" />
    </>
  );
}

export default App;
