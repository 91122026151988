import { useFetchAccountDetail } from "@business-layer/business-logic/lib/account/hooks/useFetchAccountDetail";
import { useBluelyraPlayback } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraPlayback";
import { Alert } from "@presentational/atoms/notification/Alert";
import { Navbar } from "@presentational/organisms/navbar/Navbar";
import { useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import "./styles.scss";
import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";

export function AppTemplate({ children }: { children?: React.ReactNode }) {
  const playerRef = useRef<ReactPlayer>(null);
  const { isPlaying, volume, seekTo } = useGetContextValue("bluelyraPlayback");
  const { currentVideo } = useGetContextValue("bluelyraVideo");
  const { updatePlayed, end } = useBluelyraPlayback();
  useFetchAccountDetail();

  const handleProgress = (state: OnProgressProps) => {
    updatePlayed(state.played);
  };

  useEffect(() => {
    if (seekTo && playerRef.current) {
      playerRef.current.seekTo(seekTo - 0.00001);
    }
  }, [seekTo]);

  return (
    <div className="bs-app-template no-scrollbar">
      <img
        className="bs-app-template__background"
        src="https://blueberry-space-dreamroom.b-cdn.net/account/dashboard-background/bg-1%20(1).png"
        alt="blueberry"
      />
      {currentVideo ? (
        <ReactPlayer
          ref={playerRef}
          url={`https://www.youtube.com/watch?v=${currentVideo.youtubeVideoId}`}
          playing={isPlaying}
          volume={volume}
          stopOnUnmount={true}
          progressInterval={1000}
          onEnded={end}
          onProgress={handleProgress}
          style={{ display: "none" }}
        />
      ) : null}
      <main className="bs-app-template__content">{children}</main>
      <Navbar />
      <img
        src="/assets/imgs/icons/beta-test.png"
        alt="beta"
        className="fixed top-10 right-10 w-70 h-70 z-50 pointer-events-none"
      />
      <Alert />
    </div>
  );
}
