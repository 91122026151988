import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setAccountDetail } from "../context";
import { useGetSession } from "@business-layer/business-logic/lib/auth/hooks/useGetSession";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "@business-layer/business-logic/configs";
import { accountService } from "@business-layer/services/lib/accountService";

export const useFetchAccountDetail = () => {
  const { token } = useGetSession();
  const { refetch, data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetAccountDetail, token],
    queryFn: () => accountService.getAccountDetail({ token }),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    typeof data !== "undefined" && dispatch(setAccountDetail(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { refetch, data, isLoading };
};
