export const APP_ROUTES = {
  Login: "/login",
  Register: "/register",
  Dashboard: "/dashboard",
  HiddenValley: "/hidden-valley",

  Bluelyra: "/bluelyra",
  BluelyraCreatePlaylist: "/bluelyra/create-playlist",
  BluelyraPlaylistStore: "/bluelyra/playlist-store",
  BluelyraPlaylist: "/bluelyra/playlist",
  BluelyraPlaylistDetail: "/bluelyra/playlist/detail",
  BluelyraYoutubeOAuth: "/bluelyra/youtube-oauth",

  Dreaming: "/dreaming",
  Account: "/account",
};
export const PRIVATE_ROUTES = {
  HiddenValley: APP_ROUTES.HiddenValley,
  Account: APP_ROUTES.Account,
  Dashboard: APP_ROUTES.Dashboard,
  Bluelyra: APP_ROUTES.Bluelyra,
  BluelyraYoutubeOAuth: APP_ROUTES.BluelyraYoutubeOAuth,
};
export const PUBLIC_ROUTES = {
  Login: APP_ROUTES.Login,
  Register: APP_ROUTES.Register,
};
