import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { AccountContextType } from "./type";
import { IAccountDetail } from "@business-layer/services/entities";
// import { getLocalStorage } from "@utils/helpers";
// import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";

const initialState: AccountContextType = null;

export const slice = createSlice<
  AccountContextType,
  SliceCaseReducers<AccountContextType>,
  "account",
  SliceSelectors<AccountContextType>
>({
  name: "account",
  initialState,
  reducers: {
    setAccountDetail: (
      state: AccountContextType,
      action: PayloadAction<IAccountDetail>
    ) => {
      return action.payload;
    },
  },
});

export const { setAccountDetail } = slice.actions;
export default slice.reducer;
