export const ENV = {
  app: {
    baseUrl: process.env.REACT_APP_BASE_URL ?? "",
  },
  bunnyCdn: {
    user: process.env.REACT_APP_BUNNY_USER ?? "",
    cdnHostname: process.env.REACT_APP_BUNNY_CDN_HOSTNAME ?? "",
    storageZoneName: process.env.REACT_APP_BUNNY_STORAGE_ZONE_NAME ?? "",
    apiKey: process.env.REACT_APP_BUNNY_API_KEY ?? "",
  },
  hiddenValley: {
    encryptSecret: process.env.REACT_APP_HIDDEN_VALLEY_ENCRYPT_SECRET ?? "",
  },
  server: {
    baseUrl: process.env.REACT_APP_SERVER_BASE_URL ?? "",
  },
  youtube: {
    dataApiV3ApiKey: process.env.REACT_APP_YOUTUBE_DATA_API_V3_API_KEY ?? "",
  },
  weather: {
    apiKey: process.env.REACT_APP_WEATHER_API_KEY ?? "",
    baseUrl: process.env.REACT_APP_WEATHER_BASE_URL ?? "",
  },
  googleOAuth: {
    baseUrl: process.env.REACT_APP_GOOGLE_OAUTH_BASE_URL ?? "",
    clientId: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? "",
    clientSecret: process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET ?? "",
  },
};
