import Compressor from "browser-image-compression";

interface ImageSize {
  file: File;
  width: number;
  height: number;
  size: number;
  url: string;
}

interface ProcessedImage {
  tiny: ImageSize;
  thumbnail: ImageSize;
  standard: ImageSize;
}

// Định nghĩa config với maintainAspectRatio
const IMAGE_CONFIGS = {
  tiny: {
    maxWidth: 100,
    maxHeight: 100,
    quality: 0.8,
    maintainAspectRatio: true,
  },
  thumbnail: {
    maxWidth: 400,
    maxHeight: 400,
    quality: 0.8,
    maintainAspectRatio: true,
  },
  standard: {
    maxWidth: 1920,
    maxHeight: 1920,
    quality: 0.9,
    maintainAspectRatio: true,
  },
} as const;

export const useImageProcessor = () => {
  // Helper function để tính toán dimensions mới giữ nguyên aspect ratio
  const calculateNewDimensions = (
    originalWidth: number,
    originalHeight: number,
    maxWidth: number,
    maxHeight: number,
    config: (typeof IMAGE_CONFIGS)[keyof typeof IMAGE_CONFIGS]
  ) => {
    const aspectRatio = originalWidth / originalHeight;

    let newWidth = originalWidth;
    let newHeight = originalHeight;

    // Scale down if needed while maintaining aspect ratio
    if (newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    }

    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    return {
      width: Math.round(newWidth),
      height: Math.round(newHeight),
    };
  };

  const processImage = async (imageFile: File): Promise<ProcessedImage> => {
    // Get original dimensions first
    const getOriginalDimensions = async (file: File) => {
      return new Promise<{ width: number; height: number }>((resolve) => {
        const url = URL.createObjectURL(file);
        const img = new Image();
        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
          });
          URL.revokeObjectURL(url);
        };
        img.src = url;
      });
    };

    const originalDimensions = await getOriginalDimensions(imageFile);

    const processSize = async (
      file: File,
      config: (typeof IMAGE_CONFIGS)[keyof typeof IMAGE_CONFIGS]
    ): Promise<ImageSize> => {
      try {
        // Calculate new dimensions maintaining aspect ratio
        const newDimensions = calculateNewDimensions(
          originalDimensions.width,
          originalDimensions.height,
          config.maxWidth,
          config.maxHeight,
          config
        );

        const options = {
          maxWidthOrHeight: Math.max(config.maxWidth, config.maxHeight),
          maxSizeMB: 10,
          useWebWorker: true,
          quality: config.quality,
          width: newDimensions.width,
          height: newDimensions.height,
          // Nếu cần crop để force aspect ratio
          resize: undefined,
        };

        const compressedFile = await Compressor(file, options);
        const url = URL.createObjectURL(compressedFile);

        return {
          file: compressedFile,
          width: newDimensions.width,
          height: newDimensions.height,
          size: compressedFile.size,
          url,
        };
      } catch (error) {
        console.error("Error processing image:", error);
        throw error;
      }
    };

    const [tiny, thumbnail, standard] = await Promise.all([
      processSize(imageFile, IMAGE_CONFIGS.tiny),
      processSize(imageFile, IMAGE_CONFIGS.thumbnail),
      processSize(imageFile, IMAGE_CONFIGS.standard),
    ]);

    return { tiny, thumbnail, standard };
  };

  return { processImage };
};
