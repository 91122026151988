import { BOUNCES } from "./setting/bounces";
import { REPEATS } from "./setting/repeats";
import { AnimateOptions } from "./type";

const ANIMATION_DURATION = 0.3;

const createAnimation = (options: AnimateOptions) => {
  const {
    delay = 0,
    duration = ANIMATION_DURATION,
    bounceType = "None",
    repeatLimit = "None",
    repeatType = "None",
    ease = "easeInOut",
  } = options;
  return {
    duration,
    ease,
    delay,
    ...BOUNCES[bounceType],
    ...REPEATS[repeatLimit][repeatType],
  };
};

const exitAnimation = {
  common: {
    duration: ANIMATION_DURATION,
    ease: "easeInOut",
  },
};

/**
 * Add new animations here
 */
export const animations = {
  // Base: FADE
  fade: (options: AnimateOptions) => ({
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: createAnimation(options) },
    exit: { opacity: 0, transition: exitAnimation.common },
    layout: false,
  }),
  "fade--loop-infinite": (options: AnimateOptions) => ({
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: createAnimation({
        ...options,
        repeatType: "Reverse",
        repeatLimit: "Infinite",
        ease: "linear",
      }),
    },
    exit: {
      opacity: 0,
      transition: exitAnimation.common,
    },
    layout: false,
  }),
  "fade__scale--light": (options: AnimateOptions) => ({
    initial: { opacity: 0, scale: 0.9 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: createAnimation({
        ...options,
        bounceType: "Light",
      }),
    },
    exit: { opacity: 0, scale: 0.9, transition: exitAnimation.common },
    layout: true,
  }),
  "fade__scale-x": (options: AnimateOptions) => ({
    initial: { opacity: 0, scaleX: 0.8 },
    animate: {
      opacity: 1,
      scaleX: 1,
      transition: createAnimation({
        ...options,
        bounceType: "Medium",
      }),
    },
    exit: { opacity: 0, scaleX: 0.8, transition: exitAnimation.common },
    layout: true,
  }),
  "fade__go-up": (options: AnimateOptions) => ({
    initial: { opacity: 0, y: 150 },
    animate: {
      opacity: 1,
      y: 0,
      transition: createAnimation({
        ...options,
        bounceType: "Light",
      }),
    },
    exit: { opacity: 0, y: 150, transition: exitAnimation.common },
    layout: true,
  }),
  "fade__go-down": (options: AnimateOptions) => ({
    initial: { opacity: 0, y: -150 },
    animate: {
      opacity: 1,
      y: 0,
      transition: createAnimation({
        ...options,
        bounceType: "Light",
      }),
    },
    exit: { opacity: 0, y: -150, transition: exitAnimation.common },
    layout: true,
  }),
  "fade__height-expand": (options: AnimateOptions) => ({
    initial: { height: 0, opacity: 0 },
    animate: {
      height: "auto",
      opacity: 1,
      transition: createAnimation({
        ...options,
        bounceType: "Medium",
      }),
    },
    exit: { height: 0, opacity: 0, transition: exitAnimation.common },
    layout: true,
  }),
  "fade__scale__light-move--bl-to-tr": (options: AnimateOptions) => ({
    initial: {
      opacity: 0,
      scale: 0,
      x: -20,
      y: 50,
    },
    animate: {
      opacity: 1,
      scale: 1,
      x: 0,
      y: 0,
      transition: createAnimation({
        ...options,
        bounceType: "Light",
      }),
    },
    exit: {
      opacity: 0,
      scale: 0,
      x: -20,
      y: 50,
      transition: exitAnimation.common,
    },
    layout: true,
  }),
  "fade__scale__half-move--br-to-tl": (options: AnimateOptions) => ({
    initial: {
      opacity: 0,
      scale: 0,
      x: "50%",
      y: "50%",
    },
    animate: {
      opacity: 1,
      scale: 1,
      x: 0,
      y: 0,
      transition: createAnimation({
        ...options,
        bounceType: "Light",
      }),
    },
    exit: {
      opacity: 0,
      scale: 0,
      x: "50%",
      y: "50%",
      transition: exitAnimation.common,
    },
    layout: true,
  }),

  // Base: SCALE
  "scale--light": (options: AnimateOptions) => ({
    initial: { scale: 0.8 },
    animate: {
      scale: 1,
      transition: createAnimation({
        ...options,
        bounceType: "Light",
      }),
    },
    exit: { scale: 0.8, transition: exitAnimation.common },
    layout: true,
  }),

  // Base: ROTATE
  "reverse-rotate--infinite": (options: AnimateOptions) => ({
    initial: { rotate: 0 },
    animate: {
      rotate: -360,
      transition: createAnimation({
        repeatLimit: "Infinite",
        repeatType: "Normal",
        ease: "linear",
        duration: 10,
        ...options,
      }),
    },
    exit: { rotate: 0, transition: exitAnimation.common },
    layout: false,
  }),
};
