import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { AuthContextType } from "./type";
import { getCookie } from "@utils/helpers";
import { COOKIE_KEYS } from "../constants";

const initialState: AuthContextType = {
  token: getCookie(COOKIE_KEYS.AccessToken),
  refreshToken: getCookie(COOKIE_KEYS.RefreshToken),
};

const slice = createSlice<
  AuthContextType,
  SliceCaseReducers<AuthContextType>,
  "auth",
  SliceSelectors<AuthContextType>
>({
  name: "auth",
  initialState,
  reducers: {
    setAll: (
      state: AuthContextType,
      action: PayloadAction<AuthContextType>
    ) => {
      return action.payload;
    },
    setToken: (
      state: AuthContextType,
      action: PayloadAction<AuthContextType["token"]>
    ) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    setRefreshToken: (
      state: AuthContextType,
      action: PayloadAction<AuthContextType["token"]>
    ) => {
      return {
        ...state,
        refreshToken: action.payload,
      };
    },
  },
});

export const { setAll, setToken, setRefreshToken } = slice.actions;
export default slice.reducer;
