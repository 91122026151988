import { Animation } from "@presentational/atoms/animate/Animation";

type PropsType = {
  children: React.ReactNode;
  onClose: () => void;
  style?: React.CSSProperties;
  className?: string;
};
export const Modal = (props: PropsType) => {
  return (
    <Animation
      key={`modal`}
      as="div"
      animateType="fade"
      className="fixed top-0 left-0 w-screen h-screen grid place-items-center z-40"
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-dark opacity-70 z-0"
        onClick={props.onClose}
      />
      <Animation
        as="div"
        animateType="scale--light"
        key={`modal-content`}
        className={`relative z-10 bg-primary p-20 rounded-20 ${props.className}`}
        style={props.style}
      >
        {props.children}
      </Animation>
    </Animation>
  );
};
