// Config support language
export enum Locales {
  VI = "vi",
  // EN = "en",
  // RU = "ru",
}
export const ActiveLocales = Object.values(Locales);

// Lang translation
export const LOCALE_NS = {
  AUTH: "auth",
  COMMON: "common",
  HIDDEN_VALLEY: "hidden-valley",
  SERVER: "server",
  VALIDATION: "validation",
  BLUELYRA: "bluelyra",
  WEATHER: "weather",
};
export type LOCALE_TRANSLATIONS = {
  WEATHER:
    | "widget.no-weather-data"
    | "widget.no-location-data"
    | "widget.fetching-data";
  BLUELYRA:
    | "playlist.title"
    | "playlist.create-new-playlist"
    | "playlist.search-placeholder"
    | "playlist.item-playing-label"
    | "playlist.empty-playlist"
    | "playlist.add-to-playlist-btn"
    | "create-playlist.title"
    | "create-playlist.add-image"
    | "create-playlist.label-playlist-name"
    | "create-playlist.placeholder-playlist-name"
    | "create-playlist.privacy-private"
    | "create-playlist.privacy-public"
    | "create-playlist.hint"
    | "create-playlist.free"
    | "create-playlist.paid"
    | "create-playlist.label-pricing"
    | "create-playlist.placeholder-pricing"
    | "create-playlist.cancel"
    | "create-playlist.confirm"
    | "create-playlist.success"
    | "create-playlist.fail";
  VALIDATION:
    | "common.typeError"
    | "common.required"
    | "common.invalid-length"
    | "common.email.typeError"
    | "common.email.required"
    | "common.email.invalid"
    | "common.password.typeError"
    | "common.password.min"
    | "search-youtube-form.keys.required"
    | "search-youtube-form.keys.max"
    | "hidden-valley-new-psw-form.name.required"
    | "hidden-valley-new-psw-form.name.max"
    | "hidden-valley-new-psw-form.password.required"
    | "hidden-valley-new-psw-form.password.max"
    | "hidden-valley-new-psw-form.key.max"
    | "register.username.required"
    | "register.username.max";
  COMMON:
    | "lang-label"
    | "confirm-title"
    | "cancel-title"
    | "notification.info-coming-soon"
    | "user-plan.novice"
    | "user-plan.elite"
    | "user-plan.legendary"
    | "account-page.system-action.member-pack"
    | "account-page.system-action.security"
    | "account-page.system-action.help-center"
    | "account-page.system-action.feedback"
    | "account-page.system-action.logout"
    | "account-page.tabs.diaries"
    | "account-page.tabs.friends"
    | "account-page.tabs.commission"
    | "account-page.others.account-create-at"
    | "account-page.others.add-friend-at"
    | "account-page.others.medal"
    | "account-page.others.system"
    | "account-page.others.no-description"
    | "account-page.edit-profile.modal-title"
    | "account-page.edit-profile.username-input-label"
    | "account-page.edit-profile.username-input-placeholder"
    | "account-page.edit-profile.description-input-label"
    | "account-page.edit-profile.description-input-placeholder"
    | "account-page.edit-profile.edit-success";
  HIDDEN_VALLEY:
    | "intro.greeting_1"
    | "intro.greeting_2"
    | "intro.about-hidden-valley"
    | "intro.about-encrypted"
    | "intro.get-started"
    | "dashboard.create-new-password-title"
    | "dashboard.short-create-new-password-title"
    | "dashboard.input-label-name"
    | "dashboard.input-placeholder-name"
    | "dashboard.input-label-password"
    | "dashboard.input-placeholder-password"
    | "dashboard.input-label-encrypt"
    | "dashboard.encrypt-super-name"
    | "dashboard.encrypt-super-description"
    | "dashboard.encrypt-standard-name"
    | "dashboard.encrypt-standard-description"
    | "dashboard.input-label-key"
    | "dashboard.input-placeholder-key"
    | "dashboard.input-label-icon"
    | "dashboard.confirm-create-btn-label"
    | "dashboard.alert-dialog-confirm-delete-title"
    | "dashboard.alert-dialog-confirm-delete-description"
    | "dashboard.alert-dialog-delete-success-msg"
    | "dashboard.alert-dialog-delete-fail-msg"
    | "dashboard.alert-dialog-delete-in-progress-msg"
    | "dashboard.create-new-password-success"
    | "dashboard.input-icon-add-btn-label"
    | "dashboard.input-icon-popover-title"
    | "dashboard.create-new-password-inprogress"
    | "dashboard.tag-filter-default-all";
  AUTH:
    | "greeting.authentication-go-back-button"
    | "greeting.which-methods-do-u-want"
    | "greeting.login-method"
    | "greeting.register-method"
    | "greeting.google-login-method"
    | "login.logging-in"
    | "register.username-question"
    | "register.username-placeholder"
    | "register.creating-account"
    | "common.what-is-your-email"
    | "common.email-placeholder"
    | "common.email-exist"
    | "common.password-placeholder"
    | "common.checking";
  SERVER:
    | "auth-error.sv-error-email-invalid"
    | "auth-error.sv-error-email-not-exist"
    | "auth-error.sv-error-password-invalid"
    | "auth-error.sv-error-password-length-out-of-range"
    | "auth-error.sv-error-wrong-password"
    | "auth-error.sv-error-prelogin-role-invalid"
    | "auth-error.sv-error-no-uid-provided"
    | "auth-error.sv-error-register-failed-account-exist"
    | "common-error.sv-error-type-is-string"
    | "common-error.sv-error-length-out-of-range"
    | "common-error.sv-error-not-allow-empty"
    | "common-error.sv-error-data-not-valid"
    | "common-error.sv-error-account-not-found"
    | "common-error.sv-error-internal-server-error"
    | "auth-success.sv-success-user-login-success"
    | "auth-success.sv-success-user-register-success";
};

export type LocaleTranslationFunctionPropsType = <
  NS extends keyof typeof LOCALE_NS
>(
  ns: NS,
  key: LOCALE_TRANSLATIONS[NS]
) => string;
export type ValidationTranslationsType = LOCALE_TRANSLATIONS["VALIDATION"];
export type AuthTranslationsType = LOCALE_TRANSLATIONS["AUTH"];
export type HiddenValleyTranslationsType = LOCALE_TRANSLATIONS["HIDDEN_VALLEY"];

export const SUPPORT_LOCALES: { name: Locales; label: string; flag: string }[] =
  [
    {
      name: Locales.VI,
      label: "Tiếng Việt",
      flag: "/assets/imgs/flags/vietnam.png",
    },
    // {
    //   name: TLang.EN,
    //   label: "English",
    //   flag: "/assets/imgs/flags/united-kingdom.png",
    // },
    // {
    //   name: TLang.RU,
    //   label: "Русский",
    //   flag: "/assets/imgs/flags/russia.png",
    // },
  ];
