import { PRIVATE_ROUTES, PUBLIC_ROUTES } from "../constants/urls.constants";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { PrivateRoute, PrivateRouteNoTemplate } from "./PrivateRoute";
import Login from "../pages/Login";
import HiddenValley from "../pages/HiddenValley";
import Account from "../pages/Account";
import Dashboard from "../pages/Dashboard";
import Bluelyra from "../pages/Bluelyra";
import Register from "../pages/Register";
import BluelyraYoutubeOAuth from "../pages/Bluelyra/BluelyraYoutubeOAuth";
import { useGetSession } from "@business-layer/business-logic/lib/auth/hooks/useGetSession";

function Router() {
  const session = useGetSession();
  return (
    <BrowserRouter>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route
          path={PUBLIC_ROUTES.Login}
          element={
            session.refreshToken ? (
              <Navigate to={PRIVATE_ROUTES.Dashboard} />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path={PUBLIC_ROUTES.Register}
          element={
            session.refreshToken ? (
              <Navigate to={PRIVATE_ROUTES.Dashboard} />
            ) : (
              <Register />
            )
          }
        />

        {/* PRIVATE ROUTES */}
        <Route
          element={<PrivateRoute isLogged={Boolean(session.refreshToken)} />}
        >
          <Route
            path={PRIVATE_ROUTES.Dashboard}
            element={<Dashboard key={"Dashboard"} />}
          />

          <Route
            path={PRIVATE_ROUTES.HiddenValley}
            element={<HiddenValley key={"HiddenValleyHome"} />}
          />

          <Route
            path={`${PRIVATE_ROUTES.Bluelyra}/*`}
            element={<Bluelyra key={"BLUELYRA"} />}
          />

          <Route
            path={PRIVATE_ROUTES.Account}
            element={<Account key={"Account"} />}
          />
        </Route>

        <Route
          element={
            <PrivateRouteNoTemplate isLogged={Boolean(session.refreshToken)} />
          }
        >
          <Route
            path={PRIVATE_ROUTES.BluelyraYoutubeOAuth}
            element={<BluelyraYoutubeOAuth key={"BluelyraYoutubeOAuth"} />}
          />
        </Route>

        {/* NOT FOUND */}
        <Route path={"*"} element={<Navigate to={PUBLIC_ROUTES.Login} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
