import { lazy } from "react";

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const Widgets = {
  WeatherForecast: {
    component: lazy(() =>
      delay(1000).then(() =>
        import("@presentational/organisms/widget/WeatherWidget").then(
          (module) => ({ default: module.WeatherWidget })
        )
      )
    ),
    key: "weather-forecast",
  },
  Bluelyra: {
    component: lazy(() =>
      delay(1000).then(() =>
        import("@presentational/organisms/widget/Bluelyra").then((module) => ({
          default: module.Bluelyra,
        }))
      )
    ),
    key: "bluelyra",
  },
};
export type WidgetKey = keyof typeof Widgets;
