import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { WeatherContextType } from "./type";
import { getLocalStorage } from "@utils/helpers";
import { WEATHER_LOCAL_STORAGE_KEY } from "../constants";

const initialState: WeatherContextType = getLocalStorage(
  WEATHER_LOCAL_STORAGE_KEY.WEATHER
) ?? { coord: null, weather: null, forecastAt: null };

const slice = createSlice<
  WeatherContextType,
  SliceCaseReducers<WeatherContextType>,
  "weather",
  SliceSelectors<WeatherContextType>
>({
  name: "weather",
  initialState,
  reducers: {
    setAllWeatherData: (
      state: WeatherContextType,
      action: PayloadAction<WeatherContextType>
    ) => {
      return action.payload;
    },
    setWeather: (
      state: WeatherContextType,
      action: PayloadAction<WeatherContextType["weather"]>
    ) => {
      return {
        ...state,
        weather: action.payload,
      };
    },
    setCoord: (
      state: WeatherContextType,
      action: PayloadAction<WeatherContextType["coord"]>
    ) => {
      return {
        ...state,
        coord: action.payload,
      };
    },
    setForecastAt: (
      state: WeatherContextType,
      action: PayloadAction<WeatherContextType["forecastAt"]>
    ) => {
      return {
        ...state,
        forecastAt: action.payload,
      };
    },
  },
});

export const { setWeather, setCoord, setAllWeatherData, setForecastAt } =
  slice.actions;
export default slice.reducer;
