import { UseFormRegister } from "react-hook-form";

type PropsType = {
  register: UseFormRegister<any>;
  currentLength?: any;
  errorMessage?: string;
  name: string;
  label: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "type">;
export const Input = ({
  register,
  currentLength,
  errorMessage,
  name,
  label,
  type = "text",
  ...inputProps
}: PropsType) => {
  return (
    <div className="flex flex-col gap-5 relative w-full">
      <label
        htmlFor={name}
        className="text-left paragraph paragraph--small paragraph--bold"
      >
        {label}
      </label>
      <input
        {...inputProps}
        {...register(name)}
        type={type}
        id={name}
        className="rounded-20 paragraph paragraph--standard paragraph--regular rounded-tl-0 bg-secondary outline-none border-secondary border-2 px-25 py-15 text-white disabled:cursor-not-allowed"
      />
      {errorMessage ? (
        <p className="text-red text-left text-small">{errorMessage}</p>
      ) : null}

      {inputProps.maxLength ? (
        <small className="absolute text-[10px] bottom-[2px] right-15 opacity-60">
          {currentLength} / {inputProps.maxLength}
        </small>
      ) : null}
    </div>
  );
};
