import { ValidationTranslationsType } from "@constants/locale.constants";
import * as y from "yup";

export const SearchYoutubeFormSchema = y.object({
  keys: y
    .string()
    .typeError("common.typeError" as ValidationTranslationsType)
    .required("search-youtube-form.keys.required" as ValidationTranslationsType)
    .max(300, "search-youtube-form.keys.max" as ValidationTranslationsType)
    .transform((value) => value.trim()),
});

export const CreatePlaylistFormSchema = y.object({
  // TODO: ADDLANG
  name: y
    .string()
    .typeError("")
    .required("")
    .max(255, "")
    .transform((value) => value.trim()),
  isPublic: y.boolean().typeError("").required(""),
  price: y.number().typeError("").required("").max(1000000000000, ""),
  currency: y.string().typeError("").required("").max(10, ""),
});
