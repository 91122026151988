import { useBluelyraPlaylist } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraPlaylist";
import { IBluelyraPlaylist } from "@business-layer/services/entities";
import { APP_ROUTES } from "@constants/urls.constants";
import { Icon } from "@presentational/atoms/icon/Icon";
import { Spinner } from "@presentational/atoms/loader/Spinner";
import { Color } from "@presentational/themes/colors";
import { Link } from "react-router-dom";
import { toast } from "sonner";

export const BluelyraPlaylistCard = (playlist: IBluelyraPlaylist) => {
  const { deletePlaylist, isLoading } = useBluelyraPlaylist();
  const { updateCurrentPlaylist } = useBluelyraPlaylist();

  const handleDelete = async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const { error } = await deletePlaylist(playlist);
    if (error) {
      // TODO: ADDLANG
      toast.error("Không thể xoá playlist");
    }
  };
  return (
    <Link
      to={`${APP_ROUTES.BluelyraPlaylistDetail}/${playlist.id}`}
      className="block rounded-30 bg-midnight hover:bg-primary transition-colors duration-root p-10 overflow-hidden text-white h-fit"
      onClick={() => updateCurrentPlaylist(playlist)}
    >
      <div className="relative">
        <img
          src={playlist.image.thumbnailUrl}
          alt={playlist.name}
          className="w-full aspect-video h-auto object-cover mb-15 rounded-25"
        />
        <div className="absolute bottom-5 left-10 rounded-full bg-dark bg-opacity-70 py-5 px-15 grid place-items-center">
          {/* TODO: ADDLANG */}
          <small className="text-white">{playlist.videos.length} videos</small>
        </div>
      </div>
      <p className="text-standard text-white mb-5 px-10 line-clamp-3">
        {playlist.name}
      </p>
      <div className="bs-flex-row-between px-10 pb-10">
        <div className="flex flex-row items-center gap-5">
          {playlist.isPublic ? (
            <>
              {/* TODO: ADDLANG */}
              <Icon icon="Eye" color={Color.Sky} width={18} height={18} />
              <small className="text-sky leading-3">Công khai</small>
            </>
          ) : (
            <>
              {/* TODO: ADDLANG */}
              <Icon icon="Lock" color={Color.Pink} width={18} height={18} />
              <small className="text-pink">Riêng tư</small>
            </>
          )}
        </div>

        <button type="button" onClick={handleDelete}>
          {isLoading ? (
            <Spinner size="18px" />
          ) : (
            <Icon icon="Trash" width={18} height={18} />
          )}
        </button>
      </div>
    </Link>
  );
};
