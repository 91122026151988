import { ENV } from "@constants/env.constants";

if (
  !ENV.bunnyCdn.user ||
  !ENV.bunnyCdn.apiKey ||
  !ENV.bunnyCdn.storageZoneName
) {
  console.error("INVALID BUNNY CREDENTIAL");
}

export const BUNNY_CDN_API_CONFIG = {
  REGION: "sg",
  BASE_HOSTNAME: "storage.bunnycdn.com",
  HOSTNAME: "sg.storage.bunnycdn.com",
  STORAGE_ZONE_NAME: ENV.bunnyCdn.storageZoneName,
};
export const BUNNY_API_KEY = ENV.bunnyCdn.apiKey;
export const BUNNY_USER = ENV.bunnyCdn.user;
