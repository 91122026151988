import { Modal } from "./Modal";
import { useState, useCallback } from "react";
import Cropper, { Area, Point } from "react-easy-crop";
import { Slider } from "@presentational/atoms/bar/Slider";
import { SecondaryButton } from "@presentational/atoms/button/common/SecondaryButton";
import { HighlightButton } from "@presentational/atoms/button/common/HighlightButton";
import { ButtonSizeType } from "@presentational/atoms/button/base/Button";

type PropsType = {
  imageUrl: string;
  onCancel: () => void;
  onSuccess: (output: File) => void;
};

export const ImageCropper = (props: PropsType) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const onCrop = useCallback(async () => {
    if (croppedAreaPixels) {
      const croppedImage = await getCroppedImg(
        props.imageUrl,
        croppedAreaPixels
      );
      if (croppedImage) {
        const file = new File([croppedImage], "cropped-image.png", {
          type: "image/png",
        });
        props.onSuccess(file);
      }
    }
  }, [croppedAreaPixels, props]);

  return (
    <Modal
      onClose={props.onCancel}
      className="rounded-t-20 w-[clamp(350px,50%,1000px)] h-[clamp(350px,50%,800px)] overflow-hidden !p-0 flex flex-col"
    >
      <div className="w-full relative flex-grow">
        <Cropper
          image={props.imageUrl}
          crop={crop}
          zoom={zoom}
          objectFit="cover"
          aspect={16 / 9}
          onCropChange={setCrop}
          restrictPosition={false}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="py-20 px-40">
        <Slider
          min={1 * 1000}
          max={5 * 1000}
          onChange={(value) => setZoom(value / 1000)}
        />
        <div className="flex justify-center items-center gap-20 p-15">
          {/* TODO:ADDLANG */}
          <SecondaryButton
            size={ButtonSizeType.Medium}
            onClick={props.onCancel}
          >
            <p className="text-standard">Hủy</p>
          </SecondaryButton>
          <HighlightButton size={ButtonSizeType.Medium} onClick={onCrop}>
            <p className="text-standard">Xác nhận</p>
          </HighlightButton>
          {/* TODO:ADDLANG */}
        </div>
      </div>
    </Modal>
  );
};

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
  });

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area
): Promise<Blob | null> => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, "image/png");
  });
};
