import { andianaAxios } from "../config/axios";
import { getCookie, setCookie } from "@utils/helpers";
import { authService } from "../lib/authService";
import { COOKIE_KEYS } from "@business-layer/business-logic/lib/auth/constants";
import { useAuth } from "@business-layer/business-logic/lib/auth/hooks/useAuth";
import { useAccessToken } from "@business-layer/business-logic/lib/auth/hooks/useAccessToken";
import { useRefreshToken } from "@business-layer/business-logic/lib/auth/hooks/useRefreshToken";
import { useEffect } from "react";

let authInterceptor: number | null = null;
let refreshTokenPromise: Promise<any> | null = null;

export const useRefreshSessionInterceptor = function () {
  const { logout } = useAuth();
  const { setToken } = useAccessToken();
  const { setRefreshToken } = useRefreshToken();

  const refreshSession = async (refreshToken: string) => {
    try {
      const { token: newToken, refreshToken: newRefreshToken } =
        await authService.refreshSession(refreshToken);

      setToken(newToken);
      setCookie(COOKIE_KEYS.AccessToken, newToken.value);

      setRefreshToken(newRefreshToken);
      setCookie(COOKIE_KEYS.RefreshToken, newRefreshToken.value);

      return { newToken, newRefreshToken };
    } catch (err) {
      refreshTokenPromise = null;
      throw err;
    }
  };

  if (authInterceptor === null) {
    authInterceptor = andianaAxios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshToken = getCookie(COOKIE_KEYS.RefreshToken);

          try {
            if (!refreshToken) {
              throw new Error("Renew your session!");
            }
            if (!refreshTokenPromise) {
              refreshTokenPromise = refreshSession(refreshToken);
            }

            const { newToken } = await refreshTokenPromise;
            refreshTokenPromise = null;

            originalRequest.headers.Authorization = `Bearer ${newToken.value}`;
            return andianaAxios(originalRequest);
          } catch (err) {
            logout();
            return Promise.reject(err);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  useEffect(() => {
    return () => {
      if (authInterceptor !== null) {
        andianaAxios.interceptors.response.eject(authInterceptor);
        refreshTokenPromise = null;
      }
    };
  }, []);
};
