export function SingleSectionTemplate({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <main className="w-screen h-screen overflow-hidden bg-dark relative">
      {children}
    </main>
  );
}
