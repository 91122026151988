import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "24";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.93 2H5.02C3 2 2 3 2 5.02V8.94C2 11 3 12 5.02 11.95H8.94C11 12 12 11 11.95 8.93V5.02C12 3 11 2 8.93 2ZM9.01 9.76C8.33 9.76 7.67 9.5 7.12 9.04C6.5 9.49 5.75 9.76 4.94 9.76C4.53 9.76 4.19 9.42 4.19 9.01C4.19 8.6 4.53 8.26 4.94 8.26C5.96 8.26 6.81 7.56 7.12 6.6H4.94C4.53 6.6 4.19 6.26 4.19 5.85C4.19 5.44 4.53 5.1 4.94 5.1H6.23C6.27 4.72 6.58 4.42 6.97 4.42C7.36 4.42 7.67 4.72 7.71 5.1H7.97C7.98 5.1 7.99 5.1 7.99 5.1H8.01H9C9.41 5.1 9.75 5.44 9.75 5.85C9.75 6.26 9.42 6.6 9 6.6H8.67C8.58 7.08 8.39 7.53 8.14 7.94C8.41 8.14 8.7 8.26 9.01 8.26C9.42 8.26 9.76 8.6 9.76 9.01C9.76 9.42 9.42 9.76 9.01 9.76Z"
        fill={c}
      />
      <path
        d="M9 22.75C4.73 22.75 1.25 19.27 1.25 15C1.25 14.59 1.59 14.25 2 14.25C2.41 14.25 2.75 14.59 2.75 15C2.75 17.96 4.81 20.44 7.58 21.09L7.31 20.64C7.1 20.28 7.21 19.82 7.57 19.61C7.92 19.4 8.39 19.51 8.6 19.87L9.65 21.62C9.79 21.85 9.79 22.14 9.66 22.37C9.52 22.6 9.27 22.75 9 22.75Z"
        fill={c}
      />
      <path
        d="M22.0001 9.75C21.5901 9.75 21.2501 9.41 21.2501 9C21.2501 6.04 19.1901 3.56 16.4201 2.91L16.6901 3.36C16.9001 3.72 16.7901 4.18 16.4301 4.39C16.0801 4.6 15.6101 4.49 15.4001 4.13L14.3501 2.38C14.2101 2.15 14.2101 1.86 14.3401 1.63C14.4801 1.4 14.7301 1.25 15.0001 1.25C19.2701 1.25 22.7501 4.73 22.7501 9C22.7501 9.41 22.4101 9.75 22.0001 9.75Z"
        fill={c}
      />
      <path
        d="M16.92 11.85C14.12 11.85 11.84 14.12 11.84 16.93C11.84 19.73 14.11 22.01 16.92 22.01C19.72 22.01 22 19.74 22 16.93C22 14.12 19.73 11.85 16.92 11.85ZM19.4 19.34C19.03 19.52 18.58 19.38 18.39 19L18.22 18.66H15.63L15.46 19C15.33 19.26 15.06 19.41 14.79 19.41C14.68 19.41 14.56 19.38 14.46 19.33C14.09 19.14 13.94 18.69 14.12 18.32L16.26 14.05C16.39 13.8 16.65 13.64 16.93 13.64C17.21 13.64 17.47 13.8 17.6 14.06L19.74 18.33C19.92 18.7 19.77 19.15 19.4 19.34Z"
        fill={c}
      />
      <path d="M16.38 17.1599H17.47L16.92 16.0699L16.38 17.1599Z" fill={c} />
    </svg>
  );
};

export default Icon;
