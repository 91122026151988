import { BaseIconPropsType } from "../type";

const WeatherIcon = ({ width, height, className }: BaseIconPropsType) => {
  return (
    <img
      className={className}
      src="/assets/imgs/icons/weather/clouds_sun_sunny_weather.svg"
      alt="clouds_sun_sunny_weather"
      style={{
        width,
        height,
      }}
    />
  );
};
export default WeatherIcon;
