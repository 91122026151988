import { BaseIconPropsType } from "../type";

const Logo = ({ width, height, className }: BaseIconPropsType) => {
  return (
    <img
      className={className}
      src="/assets/imgs/icons/logos/dreaming-logo.svg"
      alt="Dreaming"
      style={{
        width,
        height,
      }}
    />
  );
};
export default Logo;
