import weatherReducers from "../lib/weather/context/weatherReducers";
import { WeatherContextType } from "../lib/weather/context";
import { AccountContextType } from "../lib/account/context";
import {
  BluelyraPlaybackContextType,
  BluelyraVideoContextType,
} from "../lib/bluelyra/context/type";
import { PasswordContentType } from "../lib/hiddenValley/context";
import { AuthContextType } from "../lib/auth/context/type";
import authReducers from "../lib/auth/context/auth-slice";
import accountReducers from "../lib/account/context/accountReducers";
import bluelyraPlaybackReducers from "../lib/bluelyra/context/playback-slice";
import bluelyraVideoReducers from "../lib/bluelyra/context/video-slice";
import passwordReducers from "../lib/hiddenValley/context/passwordReducers";
import dashboardReducers from "../lib/dashboard/context/dashboardReducers";
import { DashboardContextType } from "../lib/dashboard/context";

export * from "./socialConfig";
export * from "./constants";

/**
 * MUTATION CONFIG FOR REACT-QUERY
 */
export const mutationConfig = {
  MUTATION_RETRY: 0,
  USE_QUERY_RETRY: 0,
};

export const APP_REDUCERS = {
  auth: authReducers,
  bluelyraVideo: bluelyraVideoReducers,
  bluelyraPlayback: bluelyraPlaybackReducers,
  account: accountReducers,
  passwords: passwordReducers,
  weather: weatherReducers,
  dashboard: dashboardReducers,
};

export type ContextDataType = {
  auth: AuthContextType;
  bluelyraVideo: BluelyraVideoContextType;
  bluelyraPlayback: BluelyraPlaybackContextType;
  account: AccountContextType;
  passwords: PasswordContentType;
  weather: WeatherContextType;
  dashboard: DashboardContextType;
};
