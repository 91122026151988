import { useGetSession } from "../../auth/hooks/useGetSession";
import { bluelyraService } from "@business-layer/services/lib/bluelyraService";
import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserPlaylists } from "../context/video-slice";

export const useGetAllUserPlaylists = () => {
  const { token } = useGetSession();
  const dispatch = useDispatch();
  const { userPlaylists } = useGetContextValue("bluelyraVideo");

  useEffect(() => {
    if (!userPlaylists) {
      bluelyraService
        .getAllPlaylists(token)
        .then((data) => dispatch(setUserPlaylists(data)))
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data: userPlaylists };
};
