import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import { useGetTrendingYoutubeVideos } from "@business-layer/business-logic/lib/bluelyra/hooks/useGetTrendingYoutubeVideos";
import { useSearchYoutubeVideos } from "@business-layer/business-logic/lib/bluelyra/hooks/useSearchYoutubeVideos";
import { useYoutubeOAuth } from "@business-layer/business-logic/lib/bluelyra/hooks/useYoutubeOAuth";
import { IYouTubeSearchResultItem } from "@business-layer/services/entities";
import { Animation } from "@presentational/atoms/animate/Animation";
import { HighlightButton } from "@presentational/atoms/button/common/HighlightButton";
import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { Icon } from "@presentational/atoms/icon/Icon";
import { Spinner } from "@presentational/atoms/loader/Spinner";
import { BluelyraYoutubeVideoCard } from "@presentational/molecules/card/BluelyraYoutubeVideoCard";
import { Modal } from "@presentational/molecules/overlay/Modal";
import { Color } from "@presentational/themes/colors";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

type VideoBrowsingForm = {
  search: string;
};
export const BluelyraYoutubeBrowsing = () => {
  const { search: onSearch, isLoading } = useSearchYoutubeVideos();
  const [videos, setVideos] = useState<IYouTubeSearchResultItem[]>([]);
  const { openOAuthPopup, isAuthorized } = useYoutubeOAuth();
  const {
    value: isYoutubeOAuthRequestModalActive,
    setFalse: closeYoutubeOAuthRequestModal,
  } = useBoolean(!isAuthorized);
  const { register, handleSubmit, reset } = useForm<VideoBrowsingForm>({
    defaultValues: {
      search: "",
    },
  });
  const { data: trendingVideos } = useGetTrendingYoutubeVideos();

  const handleSearch = async ({ search }: VideoBrowsingForm) => {
    const { data, error } = await onSearch(search);
    if (error) {
      // TODO: ADDLANG
      toast.error("Không thể tìm kiếm video");
      return;
    }
    console.log(data);
    setVideos(data);
    reset();
  };
  console.log(videos);
  return (
    <>
      <div className="grid place-items-center pt-40 pb-30 flex-shrink">
        <form
          className="h-45 w-[clamp(350px,65%,450px)] relative bg-dark rounded-15 overflow-hidden"
          onSubmit={handleSubmit(handleSearch)}
        >
          <img
            src="/assets/imgs/icons/youtube.svg"
            alt="youtube"
            className="w-25 h-25 absolute top-1/2 left-15 transform -translate-y-1/2 z-20"
          />
          <input
            type="text"
            {...register("search", {
              required: true,
            })}
            className="relative text-white font-main font-regular text-standard w-full h-full bg-transparent py-10 px-50 z-10"
            // TODO: ADDLANG
            placeholder="Tìm kiếm hoặc dán đường dẫn đến video"
          />
          <IconButton
            icon="SearchNormal"
            type="submit"
            width={18}
            height={18}
            className="absolute top-1/2 right-15 transform -translate-y-1/2 z-20"
          />
        </form>
      </div>
      {isLoading ? (
        <div className="grid place-items-center">
          <Spinner />
        </div>
      ) : (
        <div className="grid grid-cols-3 lg:grid-cols-4 gap-15 mx-20 pb-50 flex-grow overflow-y-auto overflow-x-hidden">
          {(videos?.length ? videos : trendingVideos?.items || [])
            .filter(
              (v) => v.kind.includes("#video") || v.id?.kind.includes("#video")
            )
            .map((v, index) => (
              <Animation
                as="form"
                key={`${index}`}
                animateType="fade__scale--light"
                delay={index * 0.1}
              >
                <BluelyraYoutubeVideoCard {...v} />
              </Animation>
            ))}
        </div>
      )}
      <AnimatePresence mode="wait">
        {isYoutubeOAuthRequestModalActive ? (
          <Modal
            onClose={closeYoutubeOAuthRequestModal}
            className="text-white flex flex-col gap-20 justify-center items-center max-w-[600px]"
          >
            {/* TODO: ADDLANG */}
            <h3 className="text-center">Yêu cầu quyền truy cập</h3>
            {/* TODO: ADDLANG */}
            <p className="text-standard text-justify">
              Để tăng trải nghiệm tìm kiếm video youtube của bạn, hãy cho phép
              chúng tôi quyền truy cập ( chỉ đọc ) thông tin video, kênh yêu
              thích của bạn và các video xu hướng của youtube nhằm đề xuất video
              phù hợp cho bạn. Yêu cầu này không bắt buộc nhưng bạn sẽ có một
              trải nghiệm tuyệt vời hơn nếu đồng ý.
            </p>
            <div className="my-20 w-full flex justify-center items-center gap-25">
              <img
                src="/assets/imgs/logo/blueberry-sensei-logo-transparent.png"
                alt="blueberry logo"
                className="w-60 h-60"
              />
              <Icon icon="ArrowSwapHorizontal" color={Color.White} />
              <img
                src="/assets/imgs/icons/youtube.svg"
                alt="youtube logo"
                className="w-60 h-60"
              />
            </div>
            <div className="flex flex-col items-center gap-15">
              <HighlightButton onClick={() => openOAuthPopup()}>
                {/* TODO: ADDLANG */}
                <p className="text-standard font-bold">Tiến hành cấp phép</p>
              </HighlightButton>
              <button
                className="w-full hover:text-pink transition-colors duration-root"
                onClick={closeYoutubeOAuthRequestModal}
              >
                {/* TODO: ADDLANG */}
                <p className="text-standard">Từ chối</p>
              </button>
            </div>
          </Modal>
        ) : null}
      </AnimatePresence>
    </>
  );
};
