import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "24";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 7H21" stroke={c} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 12H21" stroke={c} strokeWidth="1.5" strokeLinecap="round" />
      <path d="M3 17H21" stroke={c} strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default Icon;
