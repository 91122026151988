/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useMemo } from "react";
import { motion } from "framer-motion";
import {
  AnimationOptionsType,
  TransitionType,
  getAnimationStyles,
} from "@presentational/animations";

type AnimateWrapperPropsType<T extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[T] & {
    as: T;
    animateType: TransitionType;
    key: string;
    children?: React.ReactNode;
  } & AnimationOptionsType;

export const Animation = forwardRef(
  <T extends keyof JSX.IntrinsicElements>(
    {
      children,
      as,
      delay,
      duration,
      animateType,
      ...props
    }: AnimateWrapperPropsType<T>,
    ref: React.Ref<Element>
  ) => {
    const Component = motion[as as keyof typeof motion];
    const animationStyles = useMemo(
      () => getAnimationStyles(animateType, { delay, duration }),
      []
    );

    return (
      <Component ref={ref} {...animationStyles} {...props}>
        {as !== "img" ? children : null}
      </Component>
    );
  }
);
