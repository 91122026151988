import { ContextDataType } from "@business-layer/business-logic/configs";
import { useSelector } from "react-redux";
import { AuthContextType } from "../context/type";

export const useGetSession = () => {
  const authSession = useSelector<ContextDataType, AuthContextType>(
    (state) => state.auth
  );
  return authSession;
};
