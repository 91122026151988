export enum QueryKeys {
  GetAccountDetail,
  GetAllHiddenValleyPasswords,
  GetTrendingYoutubeVideos,
}
export const LOCAL_STORAGE_KEYS = {
  HIDDEN_VALLEY_PASSWORDS_ORDER: `5fccd978928b5e29e4653088f7a69cf5`,
  ACCOUNT_DETAIL: `315b94df7977bf8b1689171617ed6af9`,
};

export const ENV_KEYS = {
  HIDDEN_VALLEY_ENCRYPT_SECRET:
    process.env.REACT_APP_HIDDEN_VALLEY_ENCRYPT_SECRET ?? "",
};
