import { useNotification } from "@business-layer/business-logic/common/useNotification";
import { useYoutubeOAuth } from "@business-layer/business-logic/lib/bluelyra/hooks/useYoutubeOAuth";
import { APP_ROUTES } from "@constants/urls.constants";
import { Spinner } from "@presentational/atoms/loader/Spinner";
import { useQueryState } from "nuqs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function BluelyraYoutubeOAuth() {
  const { verify, verifyState } = useYoutubeOAuth();
  const [code] = useQueryState("code");
  const navigate = useNavigate();
  const { toastSuccess } = useNotification();

  useEffect(() => {
    verify(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (verifyState.isSuccess && verifyState.data) {
      navigate(APP_ROUTES.Bluelyra);
      toastSuccess(verifyState.data.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyState]);

  return (
    <div className="flex flex-col justify-center items-center text-center gap-25 bs-background bs-background--rounded bs-background--full !w-screen !h-screen py-35 px-60 text-white">
      {verifyState.error ? (
        <>
          {/* TODO: ADDLANG */}
          <h3>Có lỗi xảy ra</h3>
          <p className="text-red">Thông tin xác minh không hợp lệ</p>
        </>
      ) : (
        // TODO: ADDLANG
        <div className="flex items-center justify-center gap-10">
          <Spinner />
          <h3>Đang xác minh, vui lòng chờ...</h3>
        </div>
      )}
    </div>
  );
}

export default BluelyraYoutubeOAuth;
