import { ValidationTranslationsType } from "@constants/locale.constants";
import * as y from "yup";
export const CreateNewHiddenValleyPasswordSchema = y.object({
  name: y
    .string()
    .required(
      "hidden-valley-new-psw-form.name.required" as ValidationTranslationsType
    )
    .max(
      100,
      "hidden-valley-new-psw-form.name.max" as ValidationTranslationsType
    )
    .transform((value) => value.trim()),
  password: y
    .string()
    .required(
      "hidden-valley-new-psw-form.password.required" as ValidationTranslationsType
    )
    .max(
      255,
      "hidden-valley-new-psw-form.password.max" as ValidationTranslationsType
    )
    .transform((value) => value.trim()),
  key: y
    .string()
    .max(50, "hidden-valley-new-psw-form.key.max" as ValidationTranslationsType)
    .transform((value) => value.trim()),
});
