import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 8.33994V15.6599C2.5 17.1599 4.12999 18.0999 5.42999 17.3499L8.60001 15.5299L11.77 13.7C11.97 13.58 12.13 13.45 12.26 13.29V10.73C12.13 10.57 11.97 10.44 11.77 10.32L8.60001 8.48993L5.42999 6.66996C4.12999 5.89996 2.5 6.83994 2.5 8.33994Z"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.26 8.33994V15.6599C12.26 17.1599 13.89 18.0999 15.19 17.3499L18.36 15.5299L21.53 13.7C22.83 12.95 22.83 11.08 21.53 10.32L18.36 8.48993L15.19 6.66996C13.89 5.89996 12.26 6.83994 12.26 8.33994Z"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
