export const BOUNCES = {
  Light: {
    type: "spring",
    stiffness: 300,
    damping: 25,
    bounce: 0.5,
  },
  Medium: {
    type: "spring",
    stiffness: 350,
    damping: 20,
    bounce: 0.8,
  },
  Heavy: {
    type: "spring",
    stiffness: 400,
    damping: 15,
    bounce: 1,
  },
  None: {},
};
