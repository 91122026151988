import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "24";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.71 15.01C18.62 14.94 17.5 15.34 16.67 16.17C15.11 17.73 15.11 20.26 16.67 21.83C17.2 22.36 17.85 22.71 18.54 22.88C18.92 22.98 19.32 23.01 19.72 22.99C20.67 22.95 21.6 22.57 22.33 21.84C23.36 20.81 23.71 19.36 23.38 18.05C23.22 17.36 22.86 16.71 22.33 16.18C21.61 15.45 20.67 15.06 19.71 15.01ZM21.74 18.98C21.74 19.19 21.66 19.37 21.52 19.51C21.38 19.65 21.2 19.73 20.99 19.73H20.25V20.51C20.25 20.72 20.17 20.9 20.03 21.04C19.89 21.18 19.71 21.26 19.5 21.26C19.09 21.26 18.75 20.92 18.75 20.51V19.73H18C17.59 19.73 17.25 19.39 17.25 18.98C17.25 18.57 17.59 18.23 18 18.23H18.75V17.52C18.75 17.11 19.09 16.77 19.5 16.77C19.91 16.77 20.25 17.11 20.25 17.52V18.23H20.99C21.41 18.23 21.74 18.57 21.74 18.98Z"
        fill={c}
      />
      <path
        d="M12.73 12.0701C12.32 12.0701 11.99 12.4001 11.99 12.8101C11.99 13.2201 12.32 13.5501 12.73 13.5501C13.14 13.5501 13.47 13.2201 13.47 12.8101C13.47 12.4001 13.14 12.0701 12.73 12.0701Z"
        fill={c}
      />
      <path
        d="M7.26002 13.0601C6.85002 13.0601 6.52002 13.3901 6.52002 13.8001C6.52002 14.2101 6.85002 14.5401 7.26002 14.5401C7.67002 14.5401 8.00002 14.2101 8.00002 13.8001C8.00002 13.3901 7.67002 13.0601 7.26002 13.0601Z"
        fill={c}
      />
      <path
        d="M16.69 2H8.31C8.03 2 7.76 2.01 7.5 2.05C4.35 2.34 2.5 4.45 2.5 7.81V16.19C2.5 19.55 4.35 21.66 7.5 21.95C7.76 21.99 8.03 22 8.31 22H14C14.39 22 14.64 21.56 14.49 21.2C14.2 20.51 14 19.71 14 19C14 15.97 16.47 13.5 19.5 13.5C20.26 13.5 21 13.65 21.68 13.95C22.05 14.11 22.5 13.86 22.5 13.46V7.81C22.5 4.17 20.33 2 16.69 2ZM14.98 8.05V12.81C14.98 12.82 14.97 12.83 14.97 12.85C14.95 14.07 13.96 15.06 12.73 15.06C11.49 15.06 10.49 14.05 10.49 12.82C10.49 11.58 11.5 10.58 12.73 10.58C12.99 10.58 13.24 10.63 13.48 10.72V9.03L9.51 10.11V13.81C9.51 13.82 9.5 13.83 9.5 13.84C9.48 15.06 8.49 16.04 7.26 16.04C6.02 16.04 5.02 15.03 5.02 13.8C5.02 12.57 6.03 11.56 7.26 11.56C7.52 11.56 7.77 11.61 8 11.7V9.54V7.79C8 6.86 8.58 6.11 9.47 5.87L12.45 5.05C13.38 4.8 13.95 5.05 14.27 5.29C14.73 5.64 14.96 6.21 14.96 6.97V8.05H14.98Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
