import { ENV } from "@constants/env.constants";
import axiosLib from "axios";
import {
  BUNNY_CDN_API_CONFIG,
  BUNNY_API_KEY,
} from "@business-layer/services/config/cdn";

export const andianaAxios = axiosLib.create({
  baseURL: ENV.server.baseUrl,
});

export const googleOAuthAxios = axiosLib.create({
  baseURL: ENV.googleOAuth.baseUrl,
});

export const weatherAxios = axiosLib.create({
  baseURL: ENV.weather.baseUrl,
  params: {
    key: ENV.weather.apiKey,
  },
});

export const bunnyAxios = axiosLib.create({
  baseURL: `https://${BUNNY_CDN_API_CONFIG.HOSTNAME}/${BUNNY_CDN_API_CONFIG.STORAGE_ZONE_NAME}`,
  headers: {
    AccessKey: BUNNY_API_KEY,
    Accept: "application/json",
  },
});
