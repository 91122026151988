import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 10V14C2.5 16 3.5 17 5.5 17H6.93C7.3 17 7.67 17.11 7.99 17.3L10.91 19.13C13.43 20.71 15.5 19.56 15.5 16.59V7.41003C15.5 4.43003 13.43 3.29003 10.91 4.87003L7.99 6.70003C7.67 6.89003 7.3 7.00003 6.93 7.00003H5.5C3.5 7.00003 2.5 8.00003 2.5 10Z"
        stroke={c}
        strokeWidth="1.5"
      />
      <path
        d="M18.5 8C20.28 10.37 20.28 13.63 18.5 16"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.33 5.5C23.22 9.35 23.22 14.65 20.33 18.5"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
