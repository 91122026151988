import { Button, ButtonRoundedType, ButtonThemeType } from "../base/Button";
import { CommonButtonPropsType } from "./type";

export const SecondaryButton = ({
  children,
  ...props
}: CommonButtonPropsType) => {
  return (
    <Button
      theme={ButtonThemeType.Secondary}
      rounded={ButtonRoundedType.Full}
      {...props}
    >
      {children}
    </Button>
  );
};
