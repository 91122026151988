import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import { useNotification } from "@business-layer/business-logic/common/useNotification";
import { useBluelyraPlaylist } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraPlaylist";
import { APP_ROUTES } from "@constants/urls.constants";
import { Animation } from "@presentational/atoms/animate/Animation";
import { ButtonSizeType } from "@presentational/atoms/button/base/Button";
import { HighlightButton } from "@presentational/atoms/button/common/HighlightButton";
import { PrimaryButton } from "@presentational/atoms/button/common/PrimaryButton";
import { SecondaryButton } from "@presentational/atoms/button/common/SecondaryButton";
import { Icon } from "@presentational/atoms/icon/Icon";
import { Input } from "@presentational/atoms/input/Input";
import { ImageCropper } from "@presentational/molecules/overlay/ImageCropper";
import { ImagePicker } from "@presentational/molecules/picker/ImagePicker";
import {
  CreatePlaylistFormSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type CreatePlaylistFormType = {
  name: string;
  isPublic: boolean;
  price: number;
  currency: string;
};
export const CreateBluelyraPlaylist = () => {
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState<{
    value: {
      file: File;
      isFormatted: boolean;
    } | null;
    errorMessage: string | undefined;
  }>({
    value: null,
    errorMessage: undefined,
  });
  const { value: isCreating, setTrue, setFalse } = useBoolean(false);
  const resolver = useYupValidationResolver(CreatePlaylistFormSchema);
  const { create } = useBluelyraPlaylist();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<CreatePlaylistFormType>({
    defaultValues: {
      name: "",
      isPublic: true,
      price: 0,
      currency: "VND",
    },
    resolver,
  });
  const nameWatch = watch("name");
  const isPublic = watch("isPublic");
  const { toastError, toastSuccess } = useNotification();

  const createNewPlaylist = async (data: CreatePlaylistFormType) => {
    if (!thumbnail.value) {
      setThumbnail({
        // TODO: ADDLANG
        ...thumbnail,
        errorMessage: "CHUA THEM ANH CON CHO",
      });
      return;
    }

    setTrue();
    const { error: createPlaylistError } = await create({
      name: data.name,
      isPublic: data.isPublic,
      imageFile: thumbnail.value.file,
    });
    if (createPlaylistError) {
      // TODO: ADDLANG
      toastError("createPlaylistError");
      setFalse();
      return;
    }
    // TODO: ADDLANG
    toastSuccess("SUCCESS");
    reset();
    setThumbnail({
      value: null,
      errorMessage: undefined,
    });
    setFalse();
    navigate(APP_ROUTES.Bluelyra);
  };

  return (
    <Animation
      as="form"
      key="bluelyra-create-playlist-form"
      animateType="fade__go-up"
      onSubmit={handleSubmit(createNewPlaylist)}
      className="text-white w-full h-full flex flex-col"
    >
      {/* TODO: ADDLANG */}
      <button
        className="flex flex-row items-center gap-5 mb-30"
        type="button"
        onClick={() => navigate(APP_ROUTES.Bluelyra)}
      >
        <Icon icon="ArrowLeft" />
        <h3>Tạo danh sách phát mới</h3>
      </button>

      <div className="grid grid-cols-[400px,1fr] max-w-[1000px] overflow-hidden gap-25">
        <div className="w-full h-auto aspect-video col-span-1">
          <ImagePicker
            value={thumbnail.value?.file ?? null}
            disabled={isCreating}
            onChange={(file) =>
              file
                ? setThumbnail({
                    value: {
                      file: file as File,
                      isFormatted: false,
                    },
                    errorMessage: undefined,
                  })
                : setThumbnail({
                    value: null,
                    errorMessage: undefined,
                  })
            }
            errorMessage={thumbnail.errorMessage}
          />
        </div>
        <div className="w-full col-span-1">
          {/* TODO: ADDLANG */}
          <Input
            label="Tên danh sách phát"
            placeholder="Tên ngắn gọn..."
            name="name"
            register={register}
            maxLength={255}
            currentLength={nameWatch.length}
            className="w-full"
            disabled={isCreating}
            errorMessage={errors.name?.message}
          />
          <div className="flex flex-start gap-15 mt-15">
            {/* TODO: ADDLANG */}
            <PrimaryButton
              focused={isPublic}
              disabled={isCreating}
              onClick={() => setValue("isPublic", true)}
            >
              <Icon icon="Eye" />
              <p className="text-standard">Công khai</p>
            </PrimaryButton>
            {/* TODO: ADDLANG */}
            <PrimaryButton
              focused={!isPublic}
              disabled={isCreating}
              onClick={() => setValue("isPublic", false)}
            >
              <Icon icon="Lock" />
              <p className="text-standard">Riêng tư</p>
            </PrimaryButton>
          </div>
        </div>
        {/* TODO: ADDLANG */}
        <p className="text-standard mt-20 col-span-2">
          Gợi ý:{" "}
          <span className="font-bold text-sky">Danh sách phát riêng tư</span>{" "}
          chỉ một mình bạn xem được.{" "}
          <span className="font-bold text-sky">Danh sách phát công khai</span>{" "}
          mọi người đều sẽ thấy. Bạn sẽ nhận được{" "}
          <span className="font-bold text-sky">
            lượt tải về, lượt xem và được vinh danh trên bảng xếp hạng.
          </span>{" "}
          Tính năng mua bán danh sách phát sẽ được hỗ trợ trong một tương lai
          không xa.
        </p>
      </div>
      <div className="bs-flex-col-end flex-grow">
        <div className="bs-flex-row-end w-full gap-20">
          {/* TODO: ADDLANG */}
          <SecondaryButton disabled={isCreating} size={ButtonSizeType.Medium}>
            <p className="text-standard">Huỷ bỏ</p>
          </SecondaryButton>
          {/* TODO: ADDLANG */}
          <HighlightButton
            type="submit"
            isLoading={isCreating}
            size={ButtonSizeType.Medium}
          >
            <p className="text-standard">Xác nhận tạo</p>
          </HighlightButton>
        </div>
      </div>
      {thumbnail.value && !thumbnail.value.isFormatted ? (
        <ImageCropper
          onCancel={() =>
            setThumbnail({
              value: null,
              errorMessage: undefined,
            })
          }
          onSuccess={(output) =>
            setThumbnail({
              value: {
                file: output,
                isFormatted: true,
              },
              errorMessage: undefined,
            })
          }
          imageUrl={URL.createObjectURL(thumbnail.value.file)}
        />
      ) : null}
    </Animation>
  );
};
