import { useGetAllUserPlaylists } from "@business-layer/business-logic/lib/bluelyra/hooks/useGetAllUserPlaylists";
import { APP_ROUTES } from "@constants/urls.constants";
import { Animation } from "@presentational/atoms/animate/Animation";
import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { EmptyBlueberry } from "@presentational/atoms/empty/EmptyBlueberry";
import { Spinner } from "@presentational/atoms/loader/Spinner";
import { BluelyraPlaylistCard } from "@presentational/molecules/card/BluelyraPlaylistCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const BluelyraPlaylists = () => {
  const { data: playlists } = useGetAllUserPlaylists();
  const navigate = useNavigate();
  return (
    <>
      <Animation
        as="div"
        key="bluelyra-playlist-heading"
        animateType="fade"
        className="flex flex-row justify-between items-start gap-30 text-white mb-20"
      >
        <div className="flex flex-row items-center gap-35">
          {/* TODO: ADDLANG */}
          <Link to={APP_ROUTES.Bluelyra} className="text-h3 font-bold">
            Danh sách phát
          </Link>
          <Link
            to={APP_ROUTES.BluelyraPlaylistStore}
            className="text-standard opacity-50"
          >
            Cửa hàng
          </Link>
        </div>
        <IconButton
          icon="MusicSquareAdd"
          width={35}
          height={35}
          className="opacity-50 hover:opacity-100 transition-opacity duration-root"
          onClick={() => navigate(APP_ROUTES.BluelyraCreatePlaylist)}
        />
      </Animation>
      {typeof playlists !== "undefined" ? (
        playlists?.length ? (
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              0: 1,
              768: 2,
              1024: 3,
              1280: 4,
              1536: 5,
            }}
          >
            <Masonry gutter="20px">
              {playlists.map((p, index) => (
                <Animation
                  as="div"
                  key={p.id}
                  animateType="fade__go-up"
                  delay={index * 0.1}
                  className="w-full h-fit"
                >
                  <BluelyraPlaylistCard {...p} />
                </Animation>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <Animation
            as="div"
            key="bluelyra-empty-playlist"
            animateType="fade"
            className="w-150 mx-auto mt-50"
          >
            <EmptyBlueberry />
            {/* TODO: ADDLANG */}
            <p className="text-standard font-bold text-center text-white">
              Chưa có danh sách phát nào
            </p>
          </Animation>
        )
      ) : (
        <Spinner />
      )}
    </>
  );
};
