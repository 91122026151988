import { Icon } from "@presentational/atoms/icon/Icon";
import { useMemo, useRef, useState } from "react";
import "./styles.scss";
import { IMAGE_DEFAULT_MAX_SIZE } from "@constants/limit.constants";
import { AnimatePresence } from "framer-motion";
import { Color } from "@presentational/themes/colors";

type PropsType = {
  value: File | File[] | null;
  maxSize?: number; // byte
  multiple?: boolean;
  disabled?: boolean;
  onChange: (file: File | File[] | null) => void;
  errorMessage?: string;
};
export const ImagePicker = ({
  value,
  maxSize = IMAGE_DEFAULT_MAX_SIZE,
  multiple,
  onChange,
  disabled,
  errorMessage,
}: PropsType) => {
  const [internalErrorMessage, setInternalErrorMessage] = useState<
    string | null
  >(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const error = useMemo(
    () => internalErrorMessage || errorMessage,
    [internalErrorMessage, errorMessage]
  );

  return (
    <AnimatePresence mode="wait">
      {value ? (
        <button
          type="button"
          disabled={disabled}
          className="bs-image-picker bs-image-picker--selected bs-hover-show-child__parent"
          onClick={() => {
            onChange(null);
          }}
        >
          {/* TODO: Please support multiple images */}
          <img
            src={URL.createObjectURL(value as File)}
            alt="selected"
            className="w-full h-full object-cover"
          />
          <div className="bs-absolute-full bs-background bs-flex-row-center bs-hover-show-child__child">
            <Icon icon="Trash" color={Color.Red} className="mr-5" />
            {/* TODO: ADDLANG */}
            <p className="text-standard font-bold text-red">Xoá</p>
          </div>
        </button>
      ) : (
        <button
          type="button"
          className={`bs-image-picker ${error ? "bs-image-picker--error" : ""}`}
          onClick={() => inputRef.current?.click()}
        >
          <div className="bs-flex-row-center">
            <Icon icon="GalleryAdd" className="mr-5" />
            {/* TODO: ADDLANG */}
            <p className="font-bold text-standard">Thêm ảnh</p>
          </div>
          {error ? (
            <p className="text-small text-red mt-5 absolute bottom-10 left-0 w-full text-center">
              {error}
            </p>
          ) : null}
          <input
            className="hidden"
            accept="image/*"
            type="file"
            ref={inputRef}
            multiple={multiple}
            onChange={(e) => {
              if (multiple) {
                // TODO: Handle
                const fileList = e.target.files;
                if (!fileList?.length) {
                  return;
                }
                const arrayFileList = Array.from(fileList);
                if (arrayFileList.some((f) => f.size > maxSize)) {
                  // TODO: ADDLANG
                  setInternalErrorMessage("Quá size rồi con chó");
                  return;
                }
                setInternalErrorMessage(null);
                onChange(arrayFileList);
              } else {
                const file = e.target.files?.[0];
                if (!file) {
                  return;
                }
                if (file.size > maxSize) {
                  // TODO: Handle error
                  // TODO: ADDLANG
                  setInternalErrorMessage("Quá size rồi con chó");
                  return;
                }
                setInternalErrorMessage(null);
                onChange(file);
              }
            }}
          />
        </button>
      )}
    </AnimatePresence>
  );
};
