import { useCallback, useState } from "react";

/**
 * Returns a current execution state of an async function.
 * Use it to orchestrate async actions in UI.
 *
 * @see https://react-hooks-library.vercel.app/core/useAsyncCallback
 */
export function useAsyncCallback(
  callback: any,
  initState = { loading: false }
): [
  { data: any; error: any; isLoading: boolean; isSuccess: boolean },
  (...args: any) => Promise<any>
] {
  const [isLoading, setIsLoading] = useState(initState?.loading || false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(undefined);

  const fn = useCallback(
    async (...args: any) => {
      try {
        setIsLoading(true);
        const results = await callback(...args);
        setData(results);
        setIsSuccess(true);
        setIsLoading(false);
        return results;
      } catch (err: any) {
        setError(err);
        setIsLoading(false);
      }
    },
    [callback]
  );

  return [{ data, error, isLoading, isSuccess }, fn];
}
