import { useAuth } from "@business-layer/business-logic/lib/auth/hooks/useAuth";
import { useTranslation } from "@business-layer/business-logic/common/useTranslation";
import { APP_ROUTES } from "@constants/urls.constants";
import { HighlightButton } from "@presentational/atoms/button/common/HighlightButton";
import { Input } from "@presentational/atoms/input/Input";
import { SingleSectionTemplate } from "@presentational/templates/SingleSectionTemplate";
import {
  registerFormSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ButtonLayoutType } from "@presentational/atoms/button/base/Button";
import { Link } from "react-router-dom";

type TRegisterForm = {
  email: string;
  password: string;
  username: string;
};
function Register() {
  const resolver = useYupValidationResolver(registerFormSchema);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<TRegisterForm>({
    defaultValues: { email: "", password: "", username: "" },
    resolver,
  });
  const navigate = useNavigate();
  const { translate: trans } = useTranslation();
  const { register: registerAccount, isLoading } = useAuth();

  const handleRegister = async ({
    email,
    password,
    username,
  }: TRegisterForm) => {
    const { error, data } = await registerAccount({
      email,
      password,
      username,
    });
    if (error) {
      console.error(error);
      setError("root", { message: trans("VALIDATION", error.message as any) });
    } else {
      console.log(data);
      navigate(APP_ROUTES.Dashboard);
    }
  };

  return (
    <SingleSectionTemplate>
      <img
        src="/assets/imgs/background/security.png"
        alt="register"
        className="w-full h-full object-cover"
      />
      <form
        onSubmit={handleSubmit(handleRegister)}
        className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-25 overflow-hidden w-[575px]"
      >
        <div className="w-full h-full bg-primary opacity-75 absolute top-0 left-0 z-0" />
        <div className="w-full relative z-10 flex flex-col gap-25 py-50 px-95 text-white text-center">
          <h1 className="text-center">
            MỘT TÀI KHOẢN
            <br />
            TRIỆU TÍNH NĂNG
          </h1>
          <Input
            label="Email"
            placeholder="Example@gmail.com"
            name="email"
            register={register}
            disabled={isLoading}
            errorMessage={
              errors.email?.message
                ? trans("VALIDATION", errors.email.message as any)
                : undefined
            }
          />
          <Input
            label="Mật khẩu"
            placeholder="Tối thiểu 6 ký tự"
            name="password"
            register={register}
            disabled={isLoading}
            type="password"
            errorMessage={
              errors.password?.message
                ? trans("VALIDATION", errors.password.message as any)
                : undefined
            }
          />
          <Input
            label="Bí danh"
            placeholder="Tên người dùng của bạn"
            name="username"
            register={register}
            disabled={isLoading}
            type="text"
            errorMessage={
              errors.username?.message
                ? trans("VALIDATION", errors.username.message as any)
                : undefined
            }
          />
          {errors.root?.message ? (
            <p className="text-red text-center text-small">
              {errors.root.message}
            </p>
          ) : null}
          <HighlightButton
            layout={ButtonLayoutType.Expand}
            type="submit"
            isLoading={isLoading}
          >
            <p className="text-standard">Xác nhận</p>
          </HighlightButton>
          <Link to={APP_ROUTES.Login}>Đã có tài khoản?</Link>
        </div>
      </form>
    </SingleSectionTemplate>
  );
}

export default Register;
