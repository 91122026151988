import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2056 2H7.82562C4.18562 2 2.01562 4.17 2.01562 7.81V16.18C2.01562 19.83 4.18562 22 7.82562 22H16.1956C19.8356 22 22.0056 19.83 22.0056 16.19V7.81C22.0156 4.17 19.8456 2 16.2056 2ZM7.68563 5.5C7.68563 5.09 8.02563 4.75 8.43563 4.75C8.84562 4.75 9.18563 5.09 9.18563 5.5V9.4C9.18563 9.81 8.84562 10.15 8.43563 10.15C8.02563 10.15 7.68563 9.81 7.68563 9.4V5.5ZM9.53844 16.4313C9.335 16.5216 9.18563 16.7132 9.18563 16.9358V18.5C9.18563 18.91 8.84562 19.25 8.43563 19.25C8.02563 19.25 7.68563 18.91 7.68563 18.5V16.9358C7.68563 16.7132 7.53622 16.5216 7.33285 16.4311C6.37838 16.0064 5.71562 15.058 5.71562 13.95C5.71562 12.45 6.93563 11.22 8.43563 11.22C9.93563 11.22 11.1656 12.44 11.1656 13.95C11.1656 15.0582 10.4948 16.0066 9.53844 16.4313ZM16.3456 18.5C16.3456 18.91 16.0056 19.25 15.5956 19.25C15.1856 19.25 14.8456 18.91 14.8456 18.5V14.6C14.8456 14.19 15.1856 13.85 15.5956 13.85C16.0056 13.85 16.3456 14.19 16.3456 14.6V18.5ZM15.5956 12.77C14.0956 12.77 12.8656 11.55 12.8656 10.04C12.8656 8.93185 13.5365 7.98342 14.4928 7.55873C14.6962 7.46839 14.8456 7.27681 14.8456 7.05421V5.5C14.8456 5.09 15.1856 4.75 15.5956 4.75C16.0056 4.75 16.3456 5.09 16.3456 5.5V7.06421C16.3456 7.28681 16.495 7.47835 16.6984 7.56885C17.6529 7.9936 18.3156 8.94195 18.3156 10.05C18.3156 11.55 17.0956 12.77 15.5956 12.77Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
