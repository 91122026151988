import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { DashboardContextType } from "./type";

const initialState: DashboardContextType = {
  matrixLayout: [
    [null, null, null, null],
    [null, null, null, null],
    [null, null, null, null],
    ["WeatherForecast", null, null, "Bluelyra"],
  ],
};

export const slice = createSlice<
  DashboardContextType,
  SliceCaseReducers<DashboardContextType>,
  "account",
  SliceSelectors<DashboardContextType>
>({
  name: "account",
  initialState,
  reducers: {
    setAll: (_, action: PayloadAction<DashboardContextType>) => {
      return action.payload;
    },
    setMatrixLayout: (
      state: DashboardContextType,
      action: PayloadAction<DashboardContextType["matrixLayout"]>
    ) => {
      return {
        ...state,
        matrixLayout: action.payload,
      };
    },
  },
});

export const { setAll, setMatrixLayout } = slice.actions;
export default slice.reducer;
