import { IToken } from "@business-layer/services/entities";
import { removeCookie, setCookie, getCookie } from "@utils/helpers";
import { COOKIE_KEYS } from "../constants";
import { useDispatch } from "react-redux";
import * as authActions from "../context/auth-slice";

export const getToken = (): string | null => {
  return getCookie(COOKIE_KEYS.AccessToken);
};

export const useAccessToken = () => {
  const dispatch = useDispatch();

  const setToken = (newToken: IToken): void => {
    dispatch(authActions.setToken(newToken.value));
    setCookie(
      COOKIE_KEYS.AccessToken,
      newToken.value,
      new Date(newToken.expires),
      {
        secure: true,
        sameSite: "Strict",
      }
    );
  };

  const deleteToken = (): void => {
    dispatch(authActions.setToken(null));
    removeCookie(COOKIE_KEYS.AccessToken);
  };

  // Returning the hook functions
  return {
    getToken,
    setToken,
    deleteToken,
  };
};
