import { useSelector } from "react-redux";
import { ContextDataType } from "@business-layer/business-logic/configs";

export const useAccountDetail = () => {
  const accountContextData = useSelector<
    ContextDataType,
    ContextDataType["account"]
  >((state) => state.account);
  return { data: accountContextData };
};
