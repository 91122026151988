import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0556 6.82006L14.2956 2.79006C12.7256 1.69006 10.3156 1.75006 8.80562 2.92006L3.79562 6.83006C2.79562 7.61006 2.00562 9.21006 2.00562 10.4701V17.3701C2.00562 19.9201 4.07562 22.0001 6.62562 22.0001H17.4056C19.9556 22.0001 22.0256 19.9301 22.0256 17.3801V10.6001C22.0256 9.25006 21.1556 7.59006 20.0556 6.82006ZM16.8956 13.4001C16.8956 13.7901 16.5856 14.1001 16.1956 14.1001C15.8056 14.1001 15.4956 13.7901 15.4956 13.4001V13.2201L12.7756 15.9401C12.6256 16.0901 12.4256 16.1601 12.2156 16.1401C12.0156 16.1201 11.8256 16.0001 11.7156 15.8301L10.6956 14.3101L8.31562 16.6901C8.17562 16.8301 8.00561 16.8901 7.82561 16.8901C7.64562 16.8901 7.46562 16.8201 7.33562 16.6901C7.06562 16.4201 7.06562 15.9801 7.33562 15.7001L10.3156 12.7201C10.4656 12.5701 10.6656 12.5001 10.8756 12.5201C11.0856 12.5401 11.2756 12.6501 11.3856 12.8301L12.4056 14.3501L14.5156 12.2401H14.3356C13.9456 12.2401 13.6356 11.9301 13.6356 11.5401C13.6356 11.1501 13.9456 10.8401 14.3356 10.8401H16.1956C16.2856 10.8401 16.3756 10.8601 16.4656 10.8901C16.6356 10.9601 16.7756 11.1001 16.8456 11.2701C16.8856 11.3601 16.8956 11.4501 16.8956 11.5401V13.4001Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
