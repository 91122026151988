import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { BusinessLogicProvider } from "@business-layer/business-logic/provider";
import { NuqsAdapter } from "nuqs/adapters/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <NuqsAdapter>
    <BusinessLogicProvider>
      <App />
    </BusinessLogicProvider>
  </NuqsAdapter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
