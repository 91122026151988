import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 5.25H6.5C6.09 5.25 5.75 4.91 5.75 4.5C5.75 4.09 6.09 3.75 6.5 3.75H18.5C18.91 3.75 19.25 4.09 19.25 4.5C19.25 4.91 18.91 5.25 18.5 5.25Z"
        fill={c}
      />
      <path
        d="M15.5 2.75H9.5C9.09 2.75 8.75 2.41 8.75 2C8.75 1.59 9.09 1.25 9.5 1.25H15.5C15.91 1.25 16.25 1.59 16.25 2C16.25 2.41 15.91 2.75 15.5 2.75Z"
        fill={c}
      />
      <path
        d="M14.41 16.21C14.07 16.21 13.79 16.49 13.79 16.83C13.79 17.17 14.07 17.45 14.41 17.45C14.75 17.45 15.03 17.17 15.03 16.83C15.03 16.49 14.75 16.21 14.41 16.21Z"
        fill={c}
      />
      <path
        d="M10.01 17.7399C10.01 17.3999 9.73002 17.1299 9.39002 17.1299C9.05002 17.1299 8.77002 17.4099 8.77002 17.7499C8.77002 18.0899 9.05002 18.3699 9.39002 18.3699C9.73002 18.3599 10.01 18.0799 10.01 17.7399Z"
        fill={c}
      />
      <path
        d="M18.5 7H6.5C4.3 7 2.5 8.8 2.5 11V18C2.5 20.2 4.3 22 6.5 22H18.5C20.7 22 22.5 20.2 22.5 18V11C22.5 8.8 20.7 7 18.5 7ZM16.53 12.46V16.83C16.53 16.85 16.52 16.86 16.52 16.88C16.49 18.02 15.56 18.95 14.41 18.95C13.24 18.95 12.29 18 12.29 16.83C12.29 15.66 13.24 14.71 14.41 14.71C14.63 14.71 14.83 14.75 15.03 14.81V13.44L11.51 14.4V17.73V17.74C11.51 18.91 10.56 19.86 9.39 19.86C8.22 19.86 7.27 18.91 7.27 17.74C7.27 16.57 8.22 15.62 9.39 15.62C9.61 15.62 9.81 15.66 10.01 15.72V13.82V12.22C10.01 11.33 10.56 10.61 11.41 10.39L14.14 9.64C15.02 9.41 15.56 9.64 15.87 9.88C16.17 10.11 16.53 10.58 16.53 11.47V12.46Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
