import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { BluelyraVideoContextType } from "./type";
import {
  IBluelyraPlaylist,
  IBluelyraVideo,
} from "@business-layer/services/entities";

const initialState: BluelyraVideoContextType = {
  currentVideo: null,
  currentPlaylist: null,
  userPlaylists: undefined,
};

const slice = createSlice<
  BluelyraVideoContextType,
  SliceCaseReducers<BluelyraVideoContextType>,
  string,
  SliceSelectors<BluelyraVideoContextType>
>({
  name: "bluelyra-video",
  initialState,
  reducers: {
    setAll: (
      state: BluelyraVideoContextType,
      action: PayloadAction<BluelyraVideoContextType["currentVideo"]>
    ) => {
      return {
        ...state,
        currentVideo: action.payload,
      };
    },
    setCurrentVideo: (
      state: BluelyraVideoContextType,
      action: PayloadAction<BluelyraVideoContextType["currentVideo"]>
    ) => {
      return {
        ...state,
        currentVideo: action.payload,
      };
    },
    setCurrentPlaylist: (
      state: BluelyraVideoContextType,
      action: PayloadAction<BluelyraVideoContextType["currentPlaylist"]>
    ) => {
      return {
        ...state,
        currentPlaylist: action.payload,
      };
    },
    setUserPlaylists: (
      state: BluelyraVideoContextType,
      action: PayloadAction<BluelyraVideoContextType["userPlaylists"]>
    ) => {
      return {
        ...state,
        userPlaylists: action.payload,
      };
    },
    updateUserPlaylist: (
      state: BluelyraVideoContextType,
      action: PayloadAction<{
        playlistId: string;
        data: Partial<IBluelyraPlaylist>;
      }>
    ) => {
      return {
        ...state,
        userPlaylists: state.userPlaylists?.map((p) =>
          p.id === action.payload.playlistId
            ? {
                ...p,
                ...action.payload.data,
              }
            : p
        ),
      };
    },
    updateCurrentPlaylistVideos: (
      state: BluelyraVideoContextType,
      action: PayloadAction<IBluelyraVideo[]>
    ) => {
      if (state.currentPlaylist) {
        return {
          ...state,
          currentPlaylist: {
            ...state.currentPlaylist,
            videos: action.payload,
          },
        };
      }
      return state;
    },
    addUserPlaylist: (
      state: BluelyraVideoContextType,
      action: PayloadAction<IBluelyraPlaylist>
    ) => {
      return {
        ...state,
        userPlaylists: state.userPlaylists
          ? [...state.userPlaylists, action.payload]
          : [action.payload],
      };
    },
    deleteUserPlaylist: (
      state: BluelyraVideoContextType,
      action: PayloadAction<string>
    ) => {
      return {
        ...state,
        userPlaylists:
          state.userPlaylists?.filter((p) => p.id !== action.payload) ??
          state.userPlaylists,
      };
    },
  },
});

export const {
  setCurrentVideo,
  setCurrentPlaylist,
  setAll,
  setUserPlaylists,
  updateUserPlaylist,
  updateCurrentPlaylistVideos,
  addUserPlaylist,
  deleteUserPlaylist,
} = slice.actions;
export default slice.reducer;
