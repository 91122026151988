import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "24";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.18 11.86C12.78 11.86 12.42 11.64 12.25 11.28L10.8 8.39L10.38 9.17C10.15 9.6 9.68997 9.87 9.19997 9.87H8.46997C8.05997 9.87 7.71997 9.53 7.71997 9.12C7.71997 8.71 8.05997 8.37 8.46997 8.37H9.10997L9.89997 6.91C10.09 6.57 10.47 6.34 10.83 6.36C11.22 6.36 11.57 6.59 11.75 6.93L13.18 9.79L13.52 9.1C13.75 8.64 14.2 8.36 14.72 8.36H15.53C15.94 8.36 16.28 8.7 16.28 9.11C16.28 9.52 15.94 9.86 15.53 9.86H14.82L14.11 11.27C13.93 11.64 13.58 11.86 13.18 11.86Z"
        fill={c}
      />
      <path
        d="M2.75006 18.65C2.34006 18.65 2.00006 18.31 2.00006 17.9V12.2C1.95006 9.48999 2.96006 6.92999 4.84006 5.00999C6.72006 3.09999 9.24006 2.04999 11.9501 2.04999C17.4901 2.04999 22.0001 6.55999 22.0001 12.1V17.8C22.0001 18.21 21.6601 18.55 21.2501 18.55C20.8401 18.55 20.5001 18.21 20.5001 17.8V12.1C20.5001 7.38999 16.6701 3.54999 11.9501 3.54999C9.64006 3.54999 7.50006 4.43999 5.91006 6.05999C4.31006 7.68999 3.46006 9.85999 3.50006 12.18V17.89C3.50006 18.31 3.17006 18.65 2.75006 18.65Z"
        fill={c}
      />
      <path
        d="M5.94 12.45H5.81C3.71 12.45 2 14.16 2 16.26V18.14C2 20.24 3.71 21.95 5.81 21.95H5.94C8.04 21.95 9.75 20.24 9.75 18.14V16.26C9.75 14.16 8.04 12.45 5.94 12.45Z"
        fill={c}
      />
      <path
        d="M18.19 12.45H18.06C15.96 12.45 14.25 14.16 14.25 16.26V18.14C14.25 20.24 15.96 21.95 18.06 21.95H18.19C20.29 21.95 22 20.24 22 18.14V16.26C22 14.16 20.29 12.45 18.19 12.45Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
