import { APP_ROUTES } from "@constants/urls.constants";
import { BluelyraPlaylists } from "@presentational/organisms/bluelyra/BluelyraPlaylists";
import { AnimatePresence } from "framer-motion";
import { Navigate, useLocation } from "react-router-dom";
import { CreateBluelyraPlaylist } from "@presentational/organisms/bluelyra/CreateBluelyraPlaylist";
import { BluelyraVideos } from "@presentational/organisms/bluelyra/BluelyraVideos";
import { BluelyraYoutubeBrowsing } from "@presentational/organisms/bluelyra/BluelyraYoutubeBrowsing";
import { BluelyraPlaylistDetail } from "@presentational/organisms/bluelyra/BluelyraPlaylistDetail";
import { BluelyraPlaylistStore } from "@presentational/organisms/bluelyra/BluelyraPlaylistStore";

function Bluelyra() {
  const { pathname } = useLocation();

  return (
    <AnimatePresence mode="wait">
      {pathname === APP_ROUTES.Bluelyra ? (
        <div className="bs-background bs-background--rounded bs-background--full py-35 px-60">
          <BluelyraPlaylists />
        </div>
      ) : pathname === APP_ROUTES.BluelyraCreatePlaylist ? (
        <div className="bs-background bs-background--rounded bs-background--full py-35 px-60">
          <CreateBluelyraPlaylist />
        </div>
      ) : pathname === APP_ROUTES.BluelyraPlaylistStore ? (
        <div className="bs-background bs-background--rounded bs-background--full py-35 px-60">
          <BluelyraPlaylistStore />
        </div>
      ) : pathname.startsWith(APP_ROUTES.BluelyraPlaylistDetail) ? (
        <BluelyraPlaylistDetail />
      ) : pathname.startsWith(APP_ROUTES.BluelyraPlaylist) ? (
        <div className="grid grid-cols-[400px,1fr] gap-10 h-full w-full">
          <div className="bs-background bs-background--rounded h-full">
            <BluelyraVideos />
          </div>
          <div className="bs-background bs-background--rounded h-full overflow-hidden flex flex-col">
            <BluelyraYoutubeBrowsing />
          </div>
        </div>
      ) : (
        <Navigate to={APP_ROUTES.Bluelyra} />
      )}
    </AnimatePresence>
  );
}

export default Bluelyra;
