export const setLocalStorage = (key: string, value: any): void => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getLocalStorage = (key: string): any | null => {
  if (typeof window !== "undefined") {
    const value = window.localStorage.getItem(key);
    return value !== null ? JSON.parse(value) : null;
  }
  return null;
};

export const removeLocalStorage = (key: string): void => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(key);
  }
};
