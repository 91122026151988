import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";
import { useBluelyraPlayback } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraPlayback";
import { useBluelyraVideo } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraVideo";
import { IBluelyraVideo } from "@business-layer/services/entities";
import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { Spinner } from "@presentational/atoms/loader/Spinner";

export const BluelyraPlaylistVideoCard = (video: IBluelyraVideo) => {
  const { deleteVideo, isLoadingDelete } = useBluelyraVideo();
  const { play, end } = useBluelyraPlayback();
  const { currentVideo } = useGetContextValue("bluelyraVideo");
  const { isPlaying } = useGetContextValue("bluelyraPlayback");
  return (
    <div
      onClick={() => play(video)}
      className="flex flex-row gap-10 cursor-pointer"
    >
      <div className="relative min-w-200 w-200 h-fit aspect-video overflow-hidden rounded-20 bs-hover-show-child__parent">
        <img
          src={video.thumbnail}
          alt="Bluelyra Video"
          className="w-full h-full object-cover"
        />
        {currentVideo?.id === video.id && isPlaying ? (
          <div className="bs-absolute-full grid place-items-center bg-opacity-70 bg-dark">
            {/* TODO: ADDLANG */}
            <p className="text-white">Đang phát</p>
          </div>
        ) : (
          <div className="bs-absolute-full grid place-items-center bg-opacity-70 bg-dark bs-hover-show-child__child">
            {/* TODO: ADDLANG */}
            <p className="text-white">Phát ngay</p>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between text-white text-left flex-grow">
        <div className="text-inherit">
          <p className="line-clamp-2 font-regular">{video.name}</p>
          <small className="font-regular italic">{video.channel}</small>
        </div>
        <div className="flex justify-end">
          {isLoadingDelete ? (
            <Spinner />
          ) : (
            <IconButton
              icon="Trash"
              onClick={(e) => {
                e.stopPropagation();
                currentVideo?.id === video.id && isPlaying && end();
                deleteVideo(video.id);
              }}
              className="opacity-50 hover:opacity-100 transition-opacity duration-root"
            />
          )}
        </div>
      </div>
    </div>
  );
};
