import { imageStorageService } from "@business-layer/services/lib/imageStorageService";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";

export type mediaUploadType = {
  uri: string;
  path: string;
  name: string;
  type: string;
};

export const useBunny = () => {
  const { value: isLoading, setTrue, setFalse } = useBoolean(false);

  const readFileAsBuffer = async (uri: string): Promise<ArrayBuffer | null> => {
    const media = await fetch(uri);
    const fileBlob = await media.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onload = (event: ProgressEvent<FileReader>) => {
        resolve(event.target?.result as ArrayBuffer | null);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(fileBlob);
    });
  };

  /**
   *
   * @param uri Media url
   * @param path Media path. E.g /folder1/folder2
   * @param name Media name
   * @param type Mime type
   * @returns
   */
  const uploadMedia = async ({ uri, path, name, type }: mediaUploadType) => {
    try {
      setTrue();
      const extension = type.split("/")[1];
      const pathname = `${path}/${encodeURI(name)}.${extension}`;
      const fileBuffer = await readFileAsBuffer(uri);
      if (!fileBuffer) {
        // TODO: ADDLANG
        throw new Error("Lỗi xử lý ảnh!");
      }
      const uploadResponse = await imageStorageService.uploadFileToBunny({
        fileBuffer: fileBuffer,
        name: pathname,
        type: extension,
      });

      setFalse();
      return {
        data: uploadResponse,
        error: null,
      };
    } catch (error) {
      console.error(error);
      return {
        error: error as Error,
        data: null,
      };
    }
  };

  const deleteMedia = async (pathName: string) => {
    try {
      setTrue();
      const deleteResponse = await imageStorageService.deleteFileFromBunny(
        pathName
      );
      setFalse();
      return {
        data: deleteResponse,
        error: null,
      };
    } catch (error) {
      return {
        error: error as Error,
        data: null,
      };
    }
  };

  return {
    uploadMedia,
    deleteMedia,
    isLoading,
  };
};
