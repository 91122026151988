import { Link } from "react-router-dom";
import { Spinner } from "../loader/Spinner";

type PropsType = {
  url: string | null; // null is loading
  alt: string;
  theme: "circle" | "square";
  href: string;
  containerStyle?: React.CSSProperties;
};
export const AvatarLink = ({
  url,
  alt,
  theme,
  href,
  containerStyle,
}: PropsType) => {
  return (
    <Link
      to={href}
      className="w-full overflow-hidden block"
      style={{
        borderRadius: theme === "circle" ? "999px" : "0.25rem",
        ...containerStyle,
      }}
    >
      {url ? (
        <img
          src={url}
          alt={alt}
          className="w-full aspect-square h-auto object-cover"
        />
      ) : (
        <div className="w-full aspect-square h-auto bg-zinc grid place-items-center">
          <Spinner />
        </div>
      )}
    </Link>
  );
};
