import { andianaAxios } from "@business-layer/services/config/axios";
import {
  LoginParamsType,
  LoginResponseType,
  RefreshTokenResponseType,
  RegisterParamsType,
  RegisterResponseType,
} from "./type";

export * from "./type";
class AuthService {
  static instance: any;
  constructor() {
    if (AuthService.instance) {
      return AuthService.instance;
    }
    AuthService.instance = this;
  }

  login = async (data: LoginParamsType) => {
    const response = await andianaAxios<LoginResponseType>({
      method: "POST",
      url: "/auth/user-login",
      data,
    });
    return response.data;
  };
  register = async (
    data: RegisterParamsType
  ): Promise<RegisterResponseType> => {
    const response = await andianaAxios<RegisterResponseType>({
      method: "POST",
      url: "/auth/user-register",
      data,
    });
    return response.data;
  };
  logout = async (token: string) => {
    const response = await andianaAxios<LoginResponseType>({
      method: "POST",
      url: "/auth/user-logout",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };
  refreshSession = async (
    refreshToken: string
  ): Promise<RefreshTokenResponseType> => {
    const response = await andianaAxios<RefreshTokenResponseType>({
      method: "POST",
      url: "/auth/refresh",
      headers: {
        "x-bs-refresh-token": refreshToken,
      },
    });
    return response.data;
  };
}
export const authService = new AuthService();
