import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.24 5.58H18.84L15.46 2.2C15.19 1.93 14.75 1.93 14.47 2.2C14.2 2.47 14.2 2.91 14.47 3.19L16.86 5.58H7.14L9.53 3.19C9.8 2.92 9.8 2.48 9.53 2.2C9.26 1.93 8.82 1.93 8.54 2.2L5.17 5.58H4.77C3.87 5.58 2 5.58 2 8.14C2 9.11 2.2 9.75 2.62 10.17C2.86 10.42 3.15 10.55 3.46 10.62C3.75 10.69 4.06 10.7 4.36 10.7H19.64C19.95 10.7 20.24 10.68 20.52 10.62C21.36 10.42 22 9.82 22 8.14C22 5.58 20.13 5.58 19.24 5.58Z"
        fill={c}
      />
      <path
        d="M19.0902 12H4.91019C4.29019 12 3.82019 12.55 3.92019 13.16L4.76019 18.3C5.04019 20.02 5.79019 22 9.12019 22H14.7302C18.1002 22 18.7002 20.31 19.0602 18.42L20.0702 13.19C20.1902 12.57 19.7202 12 19.0902 12ZM14.8802 16.05L11.6302 19.05C11.4902 19.18 11.3102 19.25 11.1202 19.25C10.9302 19.25 10.7402 19.18 10.5902 19.03L9.09019 17.53C8.80019 17.24 8.80019 16.76 9.09019 16.47C9.39019 16.18 9.86019 16.18 10.1602 16.47L11.1502 17.46L13.8702 14.95C14.1702 14.67 14.6502 14.69 14.9302 14.99C15.2102 15.3 15.1902 15.77 14.8802 16.05Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
