import { CSSProperties } from "react";

type PropsType = {
  style?: CSSProperties;
};
export const EmptyBlueberry = ({ style }: PropsType) => {
  return (
    <img
      src="/assets/imgs/icons/empty.png"
      alt="empty"
      style={style}
      className="w-full h-auto aspect-square object-cover"
    />
  );
};
