import { useNotification } from "@business-layer/business-logic/common/useNotification";
import { useBluelyraVideo } from "@business-layer/business-logic/lib/bluelyra/hooks/useBluelyraVideo";
import { IYouTubeSearchResultItem } from "@business-layer/services/entities";

export const BluelyraYoutubeVideoCard = (video: IYouTubeSearchResultItem) => {
  const thumbnails = Object.values(video.snippet.thumbnails);
  const { addVideo } = useBluelyraVideo();
  const { toastError } = useNotification();

  const handleAddVideo = async () => {
    const { error } = await addVideo(video);
    if (error) {
      toastError((error as any).message);
    }
  };

  return (
    <button
      type="button"
      className="text-white text-left flex flex-col gap-5 relative bs-hover-show-child__parent"
      onClick={handleAddVideo}
    >
      <img
        src={thumbnails[thumbnails.length - 1].url}
        alt="Thumbnail not found"
        className="rounded-20 w-full h-fit aspect-[4/3] object-cover"
      />
      <p className="text-standard font-regular line-clamp-3">
        {video.snippet.title}
      </p>
      <small className="font-regular">{video.snippet.channelTitle}</small>
      <div className="rounded-20 w-full h-fit aspect-[4/3] bg-dark bg-opacity-60 absolute top-0 left-0 grid place-items-center bs-hover-show-child__child">
        <div className="text-[30px] font-bold w-50 h-50 rounded-full text-gold border-2 border-solid border-gold grid place-items-center">
          +
        </div>
      </div>
    </button>
  );
};
