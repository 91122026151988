import { Icon, IconPropsType } from "@presentational/atoms/icon/Icon";
import { ButtonHTMLAttributes, MouseEvent } from "react";

type PropsType = {
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  onMouseEnter?: (args: any) => void;
  onMouseLeave?: (args: any) => void;
} & IconPropsType;

export const IconButton = ({
  type = "button",
  onClick,
  disabled = false,
  className,
  onMouseEnter,
  onMouseLeave,
  ...iconProps
}: PropsType) => {
  return (
    <button
      type={type}
      className={`${className} disabled:cursor-not-allowed`}
      onClick={(e) => !disabled && onClick && onClick(e)}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Icon {...iconProps} />
    </button>
  );
};
