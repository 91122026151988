export enum Color {
  Dark = "#050014",
  LightDark = "#0C1228",
  Primary = "#24144A",
  Secondary = "#2B3C64",

  Purple = "#3C162F",
  Pink = "#724559",

  Sky = "#8D99CB",
  White = "#FFFFFF",
  Green = "#00D269",
  Red = "#E30031",
  Gray = "#827C8D",
  Zinc = "#d4d4d8",
  Gold = "#EFB511",

  Transparent = "transparent",
  Inherit = "inherit",
}
