import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.47 1H18.53C17.66 1 17.02 1.36 16.73 2C16.57 2.29 16.5 2.63 16.5 3.03V5.97C16.5 7.24 17.26 8 18.53 8H21.47C21.87 8 22.21 7.93 22.5 7.77C23.14 7.48 23.5 6.84 23.5 5.97V3.03C23.5 1.76 22.74 1 21.47 1ZM22.41 4.93C22.31 5.03 22.16 5.1 22 5.11H20.59V5.62L20.6 6.5C20.59 6.67 20.53 6.81 20.41 6.93C20.31 7.03 20.16 7.1 20 7.1C19.67 7.1 19.4 6.83 19.4 6.5V5.1L18 5.11C17.67 5.11 17.4 4.83 17.4 4.5C17.4 4.17 17.67 3.9 18 3.9L18.88 3.91H19.4V2.51C19.4 2.18 19.67 1.9 20 1.9C20.33 1.9 20.6 2.18 20.6 2.51L20.59 3.22V3.9H22C22.33 3.9 22.6 4.17 22.6 4.5C22.59 4.67 22.52 4.81 22.41 4.93Z"
        fill={c}
      />
      <path
        d="M9.5 10.3801C10.8144 10.3801 11.88 9.31456 11.88 8.00012C11.88 6.68568 10.8144 5.62012 9.5 5.62012C8.18556 5.62012 7.12 6.68568 7.12 8.00012C7.12 9.31456 8.18556 10.3801 9.5 10.3801Z"
        fill={c}
      />
      <path
        d="M21.47 8H21V12.61L20.87 12.5C20.09 11.83 18.83 11.83 18.05 12.5L13.89 16.07C13.11 16.74 11.85 16.74 11.07 16.07L10.73 15.79C10.02 15.17 8.89 15.11 8.09 15.65L4.35 18.16C4.13 17.6 4 16.95 4 16.19V7.81C4 4.99 5.49 3.5 8.31 3.5H16.5V3.03C16.5 2.63 16.57 2.29 16.73 2H8.31C4.67 2 2.5 4.17 2.5 7.81V16.19C2.5 17.28 2.69 18.23 3.06 19.03C3.92 20.93 5.76 22 8.31 22H16.69C20.33 22 22.5 19.83 22.5 16.19V7.77C22.21 7.93 21.87 8 21.47 8Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
