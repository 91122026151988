import { animations } from "./animations";
import { AnimateOptions } from "./type";

export type TransitionType = keyof typeof animations;
export type AnimationOptionsType = Pick<AnimateOptions, "delay" | "duration">;
export const getAnimationStyles = (
  animateType: TransitionType,
  options: AnimationOptionsType = {}
) => {
  return animations[animateType](options);
};
