import { BaseIconPropsType } from "../type";

const Logo = ({ width = 24, height = 24, className }: BaseIconPropsType) => {
  return (
    <img
      className={className}
      src="/assets/imgs/icons/logos/hidden-valley.svg"
      alt="Hidden Valley"
      style={{
        width,
        height,
      }}
    />
  );
};
export default Logo;
