import { Toaster } from "sonner";

export const Alert = () => {
  return (
    <Toaster
      position="top-center"
      richColors={true}
      className="heading"
      theme="light"
      expand={false}
    />
  );
};
