import { BaseIconPropsType } from "../type";

const WeatherIcon = ({ width, height, className }: BaseIconPropsType) => {
  return (
    <img
      className={className}
      src="/assets/imgs/icons/weather/1530365_rain_cloud_drizzel_weather.svg"
      alt="rain_cloud_drizzel_weather"
      style={{
        width,
        height,
      }}
    />
  );
};
export default WeatherIcon;
