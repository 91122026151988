// Importing necessary constants
import { IToken } from "@business-layer/services/entities";
import * as authActions from "../context/auth-slice";
import { removeCookie, setCookie, getCookie } from "@utils/helpers";
import { useDispatch } from "react-redux";
import { COOKIE_KEYS } from "../constants";

type RefreshTokenHook = {
  getRefreshToken: () => Promise<string | null>;
  setRefreshToken: (newToken: IToken) => void;
  deleteRefreshToken: () => void;
};

export const getRefreshToken = async (): Promise<string | null> => {
  return getCookie(COOKIE_KEYS.RefreshToken);
};

export const useRefreshToken = (): RefreshTokenHook => {
  const dispatch = useDispatch();

  const setRefreshToken = (newToken: IToken): void => {
    dispatch(authActions.setRefreshToken(newToken.value));
    setCookie(
      COOKIE_KEYS.RefreshToken,
      newToken.value,
      new Date(newToken.expires),
      {
        secure: true,
        sameSite: "Strict",
      }
    );
  };

  const deleteRefreshToken = (): void => {
    dispatch(authActions.setRefreshToken(null));
    removeCookie(COOKIE_KEYS.RefreshToken);
  };

  return {
    getRefreshToken,
    setRefreshToken,
    deleteRefreshToken,
  };
};
