import { HighlightButton } from "@presentational/atoms/button/common/HighlightButton";
import { Icon } from "@presentational/atoms/icon/Icon";
import { useNavigate } from "react-router-dom";

export const BluelyraPlaylistStore = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-full bs-flex-col-center gap-20">
      {/* TODO: ADDLANG */}
      <p className="text-standard text-white">Chức năng đang được cập nhật</p>
      <HighlightButton onClick={() => navigate(-1)}>
        <Icon icon="ArrowLeft" /> Trở lại
      </HighlightButton>
    </div>
  );
};
