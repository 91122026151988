import {
  LOCALE_NS,
  Locales,
  LocaleTranslationFunctionPropsType,
} from "@constants/locale.constants";
import { useCallback, useMemo } from "react";
import { useTranslation as useI18nTranslation } from "react-i18next";

export const useTranslation = () => {
  const { t, i18n } = useI18nTranslation();

  const translate: LocaleTranslationFunctionPropsType = useCallback(
    (ns, key) => {
      return t(key, { ns: LOCALE_NS[ns] });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changeLocale = useCallback((locales: Locales) => {
    i18n.changeLanguage(locales);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    currentLocale: useMemo(() => i18n.language as Locales, [i18n.language]),
    translate,
    changeLocale: changeLocale,
  };
};
