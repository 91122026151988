import {
  IBluelyraPlaylist,
  IBluelyraVideo,
} from "@business-layer/services/entities";

export enum Loop {
  None,
  Normal,
  Single,
}

export type BluelyraVideoContextType = {
  currentVideo: IBluelyraVideo | null;
  currentPlaylist: IBluelyraPlaylist | null;
  userPlaylists: IBluelyraPlaylist[] | null | undefined;
};

export type BluelyraPlaybackContextType = {
  isPlaying: boolean;
  loop: Loop;
  volume: number;
  played: number;
  seekTo: number | null;
  isShuffle: boolean;
};

export type BluelyraContextType = BluelyraVideoContextType &
  BluelyraPlaybackContextType;
