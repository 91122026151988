import { Suspense } from "react";
import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";
import { Widgets } from "@business-layer/business-logic/lib/dashboard/constants";
import "./styles.scss";
import { Spinner } from "@presentational/atoms/loader/Spinner";

function Dashboard() {
  const { matrixLayout } = useGetContextValue("dashboard");

  const renderMatrix = () => {
    const elements = [];
    const visited = Array(matrixLayout.length)
      .fill(0)
      .map(() => Array(matrixLayout[0].length).fill(false));

    for (let row = 0; row < matrixLayout.length; row++) {
      for (let col = 0; col < matrixLayout[row].length; col++) {
        if (!visited[row][col]) {
          const widgetKey = matrixLayout[row][col];
          let colSpan = 1;
          let rowSpan = 1;

          // Xác định colSpan cho ô hiện tại
          while (
            col + colSpan < matrixLayout[row].length &&
            matrixLayout[row][col + colSpan] === widgetKey &&
            !visited[row][col + colSpan]
          ) {
            colSpan++;
          }

          // Xác định rowSpan cho ô hiện tại
          let expandRow = true;
          while (row + rowSpan < matrixLayout.length && expandRow) {
            for (let i = 0; i < colSpan; i++) {
              if (
                matrixLayout[row + rowSpan][col + i] !== widgetKey ||
                visited[row + rowSpan][col + i]
              ) {
                expandRow = false;
                break;
              }
            }
            if (expandRow) rowSpan++;
          }

          // Đánh dấu các ô trong khối là đã xử lý
          for (let r = 0; r < rowSpan; r++) {
            for (let c = 0; c < colSpan; c++) {
              visited[row + r][col + c] = true;
            }
          }

          // Kiểm tra nếu widgetKey là null hoặc là key của widget
          if (widgetKey === null) {
            elements.push(
              <div
                key={`empty-${row}-${col}`}
                className="bs-no-widget bs-background bs-background--rounded"
                style={{
                  gridColumn: `span ${colSpan}`,
                  gridRow: `span ${rowSpan}`,
                }}
              >
                {/* TODO: ADDLANG */}
                <p className="text-standard font-regular">Chưa có widget</p>
              </div>
            );
          } else {
            const WidgetComponent = Widgets[widgetKey].component;
            elements.push(
              <div
                key={`widget-${widgetKey}-${row}-${col}`}
                className="overflow-hidden"
                style={{
                  gridColumn: `span ${colSpan}`,
                  gridRow: `span ${rowSpan}`,
                }}
              >
                <Suspense
                  fallback={
                    <div className="bs-widget-loading">
                      <Spinner />
                    </div>
                  }
                >
                  <div className="bs-widget bs-background bs-background--rounded">
                    <WidgetComponent />
                  </div>
                </Suspense>
              </div>
            );
          }
        }
      }
    }
    return elements;
  };

  return (
    <div
      className="grid gap-10 w-full h-full bs-dashboard"
      style={{
        gridTemplateColumns: `repeat(${matrixLayout[0].length}, 1fr)`,
        gridTemplateRows: `repeat(${matrixLayout.length}, 1fr)`,
      }}
    >
      {renderMatrix()}
    </div>
  );
}

export default Dashboard;
