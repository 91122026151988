import { andianaAxios } from "@business-layer/services/config/axios";
import {
  GetAccountDetailPropsType,
  GetAccountDetailResponseType,
  EditAccountDetailPropsType,
  EditAccountDetailResponseType,
} from "./type";

export * from "./type";
class AccountService {
  static instance: any;
  constructor() {
    if (AccountService.instance) {
      return AccountService.instance;
    }
    AccountService.instance = this;
  }

  getAccountDetail = async ({ token }: GetAccountDetailPropsType) => {
    const response = await andianaAxios<GetAccountDetailResponseType>({
      method: "GET",
      url: "/account/detail",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  editAccountDetail = async ({
    token,
    ...data
  }: EditAccountDetailPropsType) => {
    const response = await andianaAxios<EditAccountDetailResponseType>({
      method: "PUT",
      url: "/account/detail/edit-detail",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
    return response.data;
  };
}
export const accountService = new AccountService();
