import { useMemo } from "react";
import { BaseIconPropsType } from "./type";

// Interfaces
import ArrowLeft from "./interfaces/ArrowLeft";
import ArrowRight from "./interfaces/ArrowRight";
import Backward from "./interfaces/Backward";
import BagTick from "./interfaces/BagTick";
import Clock from "./interfaces/Clock";
import Export from "./interfaces/Export";
import Eye from "./interfaces/Eye";
import EyeSlash from "./interfaces/EyeSlash";
import Forward from "./interfaces/Forward";
import GalleryAdd from "./interfaces/GalleryAdd";
import Group from "./interfaces/Group";
import HomeTrendUp from "./interfaces/HomeTrendUp";
import Import from "./interfaces/Import";
import Lock from "./interfaces/Lock";
import Logout from "./interfaces/Logout";
import Menu from "./interfaces/Menu";
import MusicPlay from "./interfaces/MusicPlay";
import MusicPlaylist from "./interfaces/MusicPlaylist";
import MusicSquareAdd from "./interfaces/MusicSquareAdd";
import Play from "./interfaces/Play";
import RepeateMusic from "./interfaces/RepeateMusic";
import RepeateOne from "./interfaces/RepeateOne";
import SearchNormal from "./interfaces/SearchNormal";
import Send2 from "./interfaces/Send2";
import Setting3 from "./interfaces/Setting3";
import Translate from "./interfaces/Translate";
import Trash from "./interfaces/Trash";
import VolumeHigh from "./interfaces/VolumeHigh";
import Pause from "./interfaces/Pause";
import Shuffle from "./interfaces/Shuffle";
import Youtube from "./interfaces/Youtube";
import Bag2 from "./interfaces/Bag2";
import Location from "./interfaces/Location";
import CloseCircle from "./interfaces/CloseCircle";

import ArrowSwapHorizontal from "./interfaces/ArrowSwapHorizontal";
// import  from "./interfaces";
// import  from "./interfaces";
// import  from "./interfaces";
// import  from "./interfaces";
// import  from "./interfaces";
// import  from "./interfaces";

// Logos
import DreamingLogo from "./logos/Dreaming";
import HiddenValleyLogo from "./logos/HiddenValley";

// Weather
import RainCloudDrizzelWeather from "./weather/RainCloudDrizzelWeather";
import CloudsSunSunnyWeather from "./weather/CloudsSunSunnyWeather";

const Icons = {
  ArrowSwapHorizontal,
  ArrowLeft,
  ArrowRight,
  Backward,
  BagTick,
  Clock,
  Export,
  Eye,
  EyeSlash,
  Forward,
  GalleryAdd,
  Group,
  HomeTrendUp,
  Import,
  Lock,
  Logout,
  Menu,
  MusicPlay,
  MusicPlaylist,
  MusicSquareAdd,
  Play,
  RepeateMusic,
  RepeateOne,
  SearchNormal,
  Send2,
  Setting3,
  Translate,
  Trash,
  VolumeHigh,
  Pause,
  DreamingLogo,
  HiddenValleyLogo,
  RainCloudDrizzelWeather,
  CloudsSunSunnyWeather,
  Shuffle,
  Youtube,
  Bag2,
  Location,
  CloseCircle,
};
export type IconType = keyof typeof Icons;

export type IconPropsType = {
  icon: IconType;
} & BaseIconPropsType;

export const Icon = ({
  icon,
  className,
  width = 24,
  height = 24,
  color,
}: IconPropsType) => {
  const IconComponent = useMemo(() => Icons[icon], [icon]);

  return (
    <IconComponent
      width={width}
      height={height}
      color={color}
      className={className}
    />
  );
};
