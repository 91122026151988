import { googleOAuthAxios } from "@business-layer/services/config/axios";
import { AuthorizeByCodePropsType, AuthorizeByCodeResponseType } from "./type";
import { ENV } from "@constants/env.constants";

export * from "./type";

class OAuthService {
  static instance: any;

  constructor() {
    if (OAuthService.instance) {
      return OAuthService.instance;
    }
    OAuthService.instance = this;
  }

  authorizeByCode = async ({ code }: AuthorizeByCodePropsType) => {
    const response = await googleOAuthAxios<AuthorizeByCodeResponseType>({
      method: "POST",
      url: "/token",
      data: {
        code,
        redirect_uri: `${ENV.app.baseUrl}/bluelyra/youtube-oauth`,
        grant_type: "authorization_code",
        client_id: ENV.googleOAuth.clientId,
        client_secret: ENV.googleOAuth.clientSecret,
      },
    });
    return response.data;
  };
}

export const oauthService = new OAuthService();
