import { ValidationTranslationsType } from "@constants/locale.constants";
import {
  COMMON_INPUT_MAX_LENGTH,
  USERNAME_MAX_LENGTH,
} from "@constants/system.constants";
import * as y from "yup";

export const editProfileSchema = y.object({
  username: y
    .string()
    .typeError("common.typeError" as ValidationTranslationsType)
    .required("common.required" as ValidationTranslationsType)
    .max(
      USERNAME_MAX_LENGTH,
      "common.invalid-length" as ValidationTranslationsType
    )
    .transform((value) => value.trim()),
  description: y
    .string()
    .typeError("common.typeError" as ValidationTranslationsType)
    .max(
      COMMON_INPUT_MAX_LENGTH,
      "common.invalid-length" as ValidationTranslationsType
    )
    .transform((value) => value.trim()),
});
