import {
  DeleteFileFromBunnyCDNReturnType,
  UploadBunnyParamsType,
  UploadReturnType,
} from "./type";
import { bunnyAxios } from "@business-layer/services/config/axios";
import { ENV } from "@constants/env.constants";

export * from "./type";
class ImageStorageServices {
  uploadFileToBunny = async ({
    fileBuffer,
    name,
    type,
  }: UploadBunnyParamsType): Promise<UploadReturnType> => {
    await bunnyAxios({
      method: "PUT",
      url: name,
      data: fileBuffer,
    });
    return {
      url: `https://${ENV.bunnyCdn.cdnHostname}${name}`,
      pathName: name,
      type,
    };
  };

  deleteFileFromBunny = async (
    pathName: string
  ): Promise<DeleteFileFromBunnyCDNReturnType> => {
    return await bunnyAxios({
      method: "DELETE",
      url: pathName,
    });
  };
}

export const imageStorageService = new ImageStorageServices();
