import { APP_ROUTES } from "@constants/urls.constants";
import { lazy, Suspense, useCallback } from "react";
import { useTranslation } from "@business-layer/business-logic/common/useTranslation";
import { useAccountDetail } from "@business-layer/business-logic/lib/account/hooks/useAccountDetail";
import { AvatarLink } from "@presentational/atoms/link/AvatarLink";
import { AnimatePresence } from "framer-motion";
import { Animation } from "@presentational/atoms/animate/Animation";
import { TabButton } from "./TabButton";
import "./styles.scss";
import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { Icon } from "@presentational/atoms/icon/Icon";
import { Color } from "@presentational/themes/colors";
import { useAuth } from "@business-layer/business-logic/lib/auth/hooks/useAuth";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import { Link } from "react-router-dom";

const LocaleNavbarDropdown = lazy(() =>
  import("@presentational/molecules/dropdown/LocaleNavbarDropdown").then(
    (module) => ({ default: module.LocaleNavbarDropdown })
  )
);

export const Navbar = () => {
  const { translate: trans } = useTranslation();
  const { value: isSelectingLocale, toggle, setFalse } = useBoolean(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseDropdown = useCallback(() => setFalse(), []);
  const { data: accountDetail } = useAccountDetail();
  const { logout } = useAuth();

  return (
    <nav className="bs-navbar relative z-10 w-full h-screen flex flex-col justify-between items-center bs-background">
      <ul className="bs-navbar__link-list flex flex-col items-center gap-30 w-full flex-grow pt-30">
        <Link to={APP_ROUTES.Dashboard}>
          <img
            src="/assets/imgs/logo/blueberry-sensei-logo-transparent.png"
            alt="blueberry sensei"
            width={200}
            height={200}
            className="w-50 h-50 object-cover"
          />
        </Link>
        <TabButton icon="HomeTrendUp" href={APP_ROUTES.Dashboard} />
        {/* <TabButton icon="DreamingLogo" href={APP_ROUTES.Dreaming} /> */}
        <TabButton icon="MusicPlaylist" href={APP_ROUTES.Bluelyra} />
        {/* <TabButton icon="HiddenValleyLogo" href={APP_ROUTES.HiddenValley} /> */}
      </ul>
      <div className="bs-navbar__user-session">
        <AvatarLink
          url={accountDetail?.avatar ?? null}
          alt="blueberrysensei"
          theme="circle"
          href={APP_ROUTES.Account}
        />
        <hr className="w-full text-white opacity-50" />
        <div className="relative w-[24px] h-[24px]">
          <AnimatePresence mode="wait">
            {isSelectingLocale ? (
              <Suspense>
                <LocaleNavbarDropdown onClose={handleCloseDropdown} />
              </Suspense>
            ) : null}
          </AnimatePresence>

          <button
            type="button"
            className="bs-navbar__icon-button flex flex-row gap-10 flex-nowrap items-center justify-start absolute bottom-0 left-0 z-30"
            onClick={toggle}
          >
            <Icon
              icon="Translate"
              color={Color.White}
              width={24}
              height={24}
              className="min-w-[24px]"
            />

            <AnimatePresence mode="wait">
              {isSelectingLocale ? (
                <Animation as="div" key={"locale-label"} animateType="fade">
                  <p className="whitespace-nowrap text-white text-small">
                    {trans("COMMON", "lang-label")}
                  </p>
                </Animation>
              ) : null}
            </AnimatePresence>
          </button>
        </div>
        <IconButton
          onClick={logout}
          width={24}
          height={24}
          className="bs-navbar__icon-button"
          icon="Logout"
          color={Color.White}
        />
      </div>
    </nav>
  );
};
