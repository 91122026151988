import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { IconPropsType } from "@presentational/atoms/icon/Icon";
import { Color } from "@presentational/themes/colors";
import { useNavigate } from "react-router-dom";

type PropsType = {
  href: string;
} & Pick<IconPropsType, "icon">;
export const TabButton = ({ href, ...iconProps }: PropsType) => {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  return (
    <li
      className={`bs-navbar__link ${
        pathName.includes(href) ? "active" : ""
      } w-25 h-6 flex justify-center items-center`}
    >
      <IconButton
        onClick={() => navigate(href)}
        width={30}
        height={30}
        color={Color.White}
        {...iconProps}
      />
    </li>
  );
};
