import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "25";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 25 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 17.9799L6.04999 17.9899C6.95999 17.9899 7.81 17.5399 8.31 16.7899L14.7 7.20994C15.2 6.45994 16.05 5.99993 16.96 6.00993L21.51 6.02994"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 19.98L21.5 17.98"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39001 8.61993L8.31 7.11993C7.8 6.40993 6.97999 5.98993 6.10999 5.99993L3.5 6.00994"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.47 15.3799L14.69 16.9499C15.2 17.6099 16 17.9999 16.84 17.9999L21.51 17.9799"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 6.02002L19.5 4.02002"
        stroke={c}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Icon;
