import { useGetSession } from "../../auth/hooks/useGetSession";
import { useBoolean } from "@business-layer/business-logic/common/useBoolean";
import { useDispatch } from "react-redux";
import {
  IBluelyraVideo,
  IYouTubeSearchResultItem,
} from "@business-layer/services/entities";
import {
  setCurrentVideo,
  setCurrentPlaylist,
  updateUserPlaylist,
  updateCurrentPlaylistVideos,
} from "../context/video-slice";
import { bluelyraService } from "@business-layer/services/lib/bluelyraService";
import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";
import { BluelyraVideoContextType } from "../context/type";

export const useBluelyraVideo = () => {
  const { token } = useGetSession();
  const {
    value: isLoadingAdd,
    setTrue: setLoadingAddTrue,
    setFalse: setLoadingAddFalse,
  } = useBoolean(false);
  const {
    value: isLoadingDelete,
    setTrue: setLoadingDeleteTrue,
    setFalse: setLoadingDeleteFalse,
  } = useBoolean(false);
  const dispatch = useDispatch();
  const { currentPlaylist, currentVideo } = useGetContextValue("bluelyraVideo");

  async function addVideo(video: IYouTubeSearchResultItem) {
    try {
      setLoadingAddTrue();
      const youtubeVideoId =
        typeof video.id === "string" ? video.id : video.id?.videoId;

      if (!currentPlaylist || !youtubeVideoId) {
        return {
          error: new Error("TODO:ADDLANG"),
        };
      }

      if (
        currentPlaylist.videos.find((p) => p.youtubeVideoId === youtubeVideoId)
      ) {
        return {
          error: new Error("TODO:ADDLANG"),
        };
      }

      const videoThumbs = Object.values(video.snippet.thumbnails);
      const addedVideo = await bluelyraService.addVideoToPlaylist({
        token,
        playlistId: currentPlaylist.id,
        youtubeVideoId,
        thumbnail: videoThumbs[videoThumbs.length - 1]?.url ?? "",
        name: video.snippet.title,
        channel: video.snippet.channelTitle,
      });

      const newPlaylist = {
        ...currentPlaylist,
        videos: [...currentPlaylist.videos, addedVideo],
      };

      dispatch(setCurrentPlaylist(newPlaylist));
      dispatch(
        updateUserPlaylist({
          playlistId: currentPlaylist.id,
          data: {
            videos: [...currentPlaylist.videos, addedVideo],
          },
        })
      );
      setLoadingAddFalse();
      return {
        error: null,
      };
    } catch (error) {
      setLoadingAddFalse();
      return {
        error,
      };
    }
  }
  async function deleteVideo(videoId: string) {
    try {
      setLoadingDeleteTrue();
      if (!currentPlaylist) {
        return;
      }

      await bluelyraService.deleteVideoFromPlaylist({
        token,
        playlistId: currentPlaylist.id,
        videoId,
      });
      const newPlaylist = {
        ...currentPlaylist,
        videos: currentPlaylist.videos.filter((video) => video.id !== videoId),
      };
      dispatch(setCurrentPlaylist(newPlaylist));
      dispatch(
        updateUserPlaylist({
          playlistId: currentPlaylist.id,
          data: {
            videos: currentPlaylist.videos.filter(
              (video) => video.id !== videoId
            ),
          },
        })
      );
      if (videoId === currentVideo?.id) {
        dispatch(setCurrentVideo(null));
      }
      setLoadingDeleteFalse();
    } catch (error) {
      setLoadingDeleteFalse();
      return {
        error,
      };
    }
  }
  function updateCurrentVideo(video: BluelyraVideoContextType["currentVideo"]) {
    dispatch(setCurrentVideo(video));
  }
  function updateUserCurrentPlaylistVideos(video: IBluelyraVideo[]) {
    dispatch(updateCurrentPlaylistVideos(video));
  }
  return {
    addVideo,
    deleteVideo,
    updateCurrentVideo,
    updateUserCurrentPlaylistVideos,
    isLoadingAdd,
    isLoadingDelete,
  };
};
