import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { ActiveLocales, LOCALE_NS, Locales } from "@constants/locale.constants";
import Backend from "i18next-http-backend";

i18n
  .use(Backend)
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ActiveLocales,
    fallbackLng: Locales.VI,
    debug: false,
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "locale",
      caches: ["localStorage"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.locales.json",
    },
    ns: Object.values(LOCALE_NS),
    react: {
      useSuspense: false,
    },
  });

export default i18n;
