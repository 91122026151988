import React from "react";

type SliderProps = {
  min: number;
  max: number;
  defaultValue?: number;
  onChange: (value: number) => void;
};

export const Slider: React.FC<SliderProps> = ({
  min,
  max,
  onChange,
  defaultValue = 0,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(e.target.value);
    onChange(newValue);
  };

  return (
    <div className="relative w-full">
      <input
        type="range"
        min={min}
        max={max}
        defaultValue={defaultValue}
        onChange={handleChange}
        className="w-full h-5 bg-white rounded-full appearance-none cursor-pointer"
        style={{
          WebkitAppearance: "none",
          outline: "none",
        }}
      />
    </div>
  );
};
