import { oauthService } from "@business-layer/services/lib/oauthService";
import { ENV } from "@constants/env.constants";
import { getCookie, setCookie } from "@utils/helpers";
import { CookieKeys } from "../constants";
import { useAsyncCallback } from "@business-layer/business-logic/common/useAsyncCallback";
import { useMemo } from "react";

export const useYoutubeOAuth = () => {
  const isAuthorized = useMemo(
    () => getCookie(CookieKeys.YoutubeAccessToken),
    []
  );

  const openOAuthPopup = () => {
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${ENV.googleOAuth.clientId}&redirect_uri=${ENV.app.baseUrl}/bluelyra/youtube-oauth&response_type=code&scope=https://www.googleapis.com/auth/youtube.readonly&access_type=offline`;
  };

  const [verifyState, verify] = useAsyncCallback(
    async (code: string | null) => {
      if (!code) {
        throw new Error("Code không hợp lệ");
      }
      const response = await oauthService.authorizeByCode({ code });

      const expiresInTime = new Date().getTime() + response.expires_in * 1000;
      setCookie(
        CookieKeys.YoutubeAccessToken,
        response.access_token,
        expiresInTime
      );
      setCookie(
        CookieKeys.YoutubeRefreshToken,
        response.refresh_token,
        expiresInTime
      );

      return {
        message: "Xác nhận người dùng thành công!",
      };
    }
  );
  return { openOAuthPopup, verify, verifyState, isAuthorized };
};
