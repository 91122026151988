import { Button, ButtonRoundedType, ButtonThemeType } from "../base/Button";
import { CommonButtonPropsType } from "./type";

export const GoldenButton = ({ children, ...props }: CommonButtonPropsType) => {
  return (
    <Button
      theme={ButtonThemeType.Golden}
      rounded={ButtonRoundedType.Full}
      {...props}
    >
      {children}
    </Button>
  );
};
