import "./styles.scss";
import { Color } from "@presentational/themes/colors";

type PropsType = {
  color?: Color;
  size?: string;
  thick?: string;
};
export const Spinner = ({
  color = Color.Gray,
  size = "1.25rem",
  thick = "0.15rem",
}: PropsType) => {
  return (
    <div className="flex flex-row items-center gap-15">
      <div
        className="circle-spinner rounded-full border-solid"
        style={{
          borderWidth: thick,
          borderColor: color,
          borderLeftColor: Color.Transparent,
          width: size,
          height: size,
        }}
      ></div>
    </div>
  );
};
