import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { BluelyraPlaybackContextType, Loop } from "./type";

const initialState: BluelyraPlaybackContextType = {
  isPlaying: false,
  loop: Loop.None,
  volume: 0.8,
  played: 0,
  seekTo: null,
  isShuffle: false,
};

const slice = createSlice<
  BluelyraPlaybackContextType,
  SliceCaseReducers<BluelyraPlaybackContextType>,
  "bluelyra-process",
  SliceSelectors<BluelyraPlaybackContextType>
>({
  name: "bluelyra-process",
  initialState,
  reducers: {
    setAll: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType>
    ) => {
      return action.payload;
    },
    setVolume: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType["volume"]>
    ) => {
      return {
        ...state,
        volume: action.payload,
      };
    },
    setPlayed: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType["played"]>
    ) => {
      return {
        ...state,
        played: action.payload,
      };
    },
    setIsPlaying: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType["isPlaying"]>
    ) => {
      return {
        ...state,
        isPlaying: action.payload,
      };
    },
    setLoop: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType["loop"]>
    ) => {
      return {
        ...state,
        loop: action.payload,
      };
    },
    setSeekTo: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType["seekTo"]>
    ) => {
      return {
        ...state,
        seekTo: action.payload,
      };
    },
    setIsShuffle: (
      state: BluelyraPlaybackContextType,
      action: PayloadAction<BluelyraPlaybackContextType["isShuffle"]>
    ) => {
      return {
        ...state,
        isShuffle: action.payload,
      };
    },
  },
});

export const {
  setAll,
  setVolume,
  setPlayed,
  setIsPlaying,
  setLoop,
  setSeekTo,
  setIsShuffle,
} = slice.actions;
export default slice.reducer;
