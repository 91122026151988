import { useEffect } from "react";
import { useGetAllUserPlaylists } from "./useGetAllUserPlaylists";
import { useBluelyraPlaylist } from "./useBluelyraPlaylist";

export const useGetUserPlaylistVideos = (playlistId: string) => {
  const { data: userPlaylists } = useGetAllUserPlaylists();
  const currentPlaylist = userPlaylists?.find((p) => p.id === playlistId);
  const videos = currentPlaylist?.videos;
  const { updateCurrentPlaylist } = useBluelyraPlaylist();

  useEffect(() => {
    currentPlaylist && updateCurrentPlaylist(currentPlaylist);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlaylist]);

  return { videos, currentPlaylist };
};
