import { bluelyraService } from "@business-layer/services/lib/bluelyraService";
import { getCookie } from "@utils/helpers";
import { CookieKeys } from "../constants";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "@business-layer/business-logic/configs";

export const useGetTrendingYoutubeVideos = () => {
  const token = getCookie(CookieKeys.YoutubeAccessToken);
  const { refetch, data, isLoading } = useQuery({
    queryKey: [QueryKeys.GetTrendingYoutubeVideos, token],
    queryFn: () => bluelyraService.getTrendingVideos({ token }),
  });

  return { refetch, data, isLoading };
};
