import { BaseIconPropsType } from "../type";

const Icon = ({ width, height, color, className }: BaseIconPropsType) => {
  const w = width || "24";
  const h = height || "24";
  const c = color || "#FFFFFF";
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 24 24"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9602 8.96002C19.2902 8.22002 18.2802 7.79002 16.8802 7.64002V6.88002C16.8802 5.51002 16.3002 4.19002 15.2802 3.27002C14.2502 2.33002 12.9102 1.89002 11.5202 2.02002C9.13023 2.25002 7.12023 4.56002 7.12023 7.06002V7.64002C5.72023 7.79002 4.71023 8.22002 4.04023 8.96002C3.07023 10.04 3.10023 11.48 3.21023 12.48L3.91023 18.05C4.12023 20 4.91023 22 9.21023 22H14.7902C19.0902 22 19.8802 20 20.0902 18.06L20.7902 12.47C20.9002 11.48 20.9202 10.04 19.9602 8.96002ZM11.6602 3.41002C12.6602 3.32002 13.6102 3.63002 14.3502 4.30002C15.0802 4.96002 15.4902 5.90002 15.4902 6.88002V7.58002H8.51023V7.06002C8.51023 5.28002 9.98023 3.57002 11.6602 3.41002ZM8.42023 13.15H8.41023C7.86023 13.15 7.41023 12.7 7.41023 12.15C7.41023 11.6 7.86023 11.15 8.41023 11.15C8.97023 11.15 9.42023 11.6 9.42023 12.15C9.42023 12.7 8.97023 13.15 8.42023 13.15ZM15.4202 13.15H15.4102C14.8602 13.15 14.4102 12.7 14.4102 12.15C14.4102 11.6 14.8602 11.15 15.4102 11.15C15.9702 11.15 16.4202 11.6 16.4202 12.15C16.4202 12.7 15.9702 13.15 15.4202 13.15Z"
        fill={c}
      />
    </svg>
  );
};

export default Icon;
