export const REPEATS = {
  None: {
    None: {},
    Normal: {},
    Reverse: {},
    Mirror: {},
  },
  Infinite: {
    None: {},
    Normal: {
      repeat: Infinity,
      repeatType: "loop",
    },
    Reverse: {
      repeat: Infinity,
      repeatType: "reverse",
    },
    Mirror: {
      repeat: Infinity,
      repeatType: "mirror",
    },
  },
};

export type RepeatType = keyof (typeof REPEATS)["Infinite"];
export type RepeatLimitType = keyof typeof REPEATS;
