import { forwardRef } from "react";
import "./styles.scss";
import { Spinner } from "@presentational/atoms/loader/Spinner";
import { Color } from "@presentational/themes/colors";

export type ButtonProps = {
  children: React.ReactNode;
  theme: ButtonThemeType;
  size?: ButtonSizeType;
  layout?: ButtonLayoutType;
  rounded?: ButtonRoundedType;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  focused?: boolean;
  type?: "button" | "reset" | "submit";
  style?: React.CSSProperties;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      size = "sm",
      layout = "fit",
      theme,
      rounded = "full",
      onClick,
      disabled = false,
      isLoading = false,
      focused = false,
      type = "button",
      style = {},
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={`bs-button bs-button--${theme} bs-button--${theme}-${
          focused ? "focused" : "normal"
        } bs-button--${size} bs-button--${layout} bs-button--rounded-${rounded} ${
          isLoading ? "bs-button--loading" : ""
        }`}
        style={style}
        type={type}
        onClick={onClick}
        disabled={disabled || isLoading}
      >
        {children}
        {isLoading ? (
          <span className="absolute top-0 left-0 w-full h-full grid place-items-center z-10">
            <Spinner size="0.75rem" color={Color.White} />
          </span>
        ) : null}
      </button>
    );
  }
);

export enum ButtonSizeType {
  Small = "sm",
  Medium = "md",
  Large = "lg",
}

export enum ButtonLayoutType {
  Expand = "expand",
  Fit = "fit",
}

export enum ButtonThemeType {
  Primary = "primary",
  Pink = "pink",
  Secondary = "secondary",
  Golden = "gold",
}

export enum ButtonRoundedType {
  Full = "full",
  Soft = "soft",
}
