import { PUBLIC_ROUTES } from "@constants/urls.constants";
import { AppTemplate } from "@presentational/templates/app-template/AppTemplate";
import { Navigate, Outlet } from "react-router-dom";

export function PrivateRoute(props: { isLogged: boolean }) {
  return props.isLogged ? (
    <AppTemplate>
      <Outlet />
    </AppTemplate>
  ) : (
    <Navigate to={PUBLIC_ROUTES.Login} />
  );
}

export function PrivateRouteNoTemplate(props: { isLogged: boolean }) {
  return props.isLogged ? <Outlet /> : <Navigate to={PUBLIC_ROUTES.Login} />;
}
