import { useDispatch } from "react-redux";
import * as playbackActions from "../context/playback-slice";
import { BluelyraPlaybackContextType, Loop } from "../context/type";
import { useBluelyraVideo } from "./useBluelyraVideo";
import { IBluelyraVideo } from "@business-layer/services/entities";
import { useGetContextValue } from "@business-layer/business-logic/common/useGetContextValue";
import { getRandomInt } from "@utils/helpers/random.helper";

export const useBluelyraPlayback = () => {
  const dispatch = useDispatch();
  const { isPlaying, loop, isShuffle } = useGetContextValue("bluelyraPlayback");
  const { currentPlaylist: playlist, currentVideo } =
    useGetContextValue("bluelyraVideo");
  const { updateCurrentVideo } = useBluelyraVideo();

  function reset(payload: BluelyraPlaybackContextType) {
    dispatch(playbackActions.setAll(payload));
  }
  function updateIsPlaying(isPlaying: boolean) {
    dispatch(playbackActions.setIsPlaying(isPlaying));
  }
  function updateIsShuffle(isShuffle: boolean) {
    dispatch(playbackActions.setIsShuffle(isShuffle));
  }
  function updatePlayed(newPlayed: number) {
    dispatch(playbackActions.setPlayed(newPlayed));
  }
  function updateSeekTo(val: number) {
    dispatch(playbackActions.setSeekTo(val));
  }
  function updateVolume(newVolume: number) {
    dispatch(playbackActions.setVolume(newVolume));
  }
  function updateLoop(loop: Loop) {
    dispatch(playbackActions.setLoop(loop));
  }
  function toggleLoop() {
    switch (loop) {
      case Loop.None: {
        updateLoop(Loop.Normal);
        break;
      }
      case Loop.Normal: {
        updateLoop(Loop.Single);
        break;
      }
      case Loop.Single: {
        updateLoop(Loop.None);
        break;
      }
    }
  }
  function play(video: IBluelyraVideo) {
    updateCurrentVideo(video);
    updateIsPlaying(
      (currentVideo && currentVideo.id !== video.id) || !isPlaying
    );
  }
  function next() {
    if (!playlist?.videos.length || !currentVideo) {
      updateIsPlaying(false);
      return;
    }
    const videoIndex = playlist.videos.findIndex(
      ({ id: videoId }) => videoId === currentVideo.id
    );
    if (videoIndex >= 0) {
      updateCurrentVideo(
        playlist.videos[(videoIndex + 1) % playlist.videos.length]
      );
    } else {
      updateIsPlaying(false);
    }
  }
  function prev() {
    if (!playlist?.videos.length || !currentVideo) {
      updateIsPlaying(false);
      return;
    }

    const videoIndex = playlist.videos.findIndex(
      ({ id: videoId }) => videoId === currentVideo.id
    );
    if (videoIndex >= 0) {
      updateCurrentVideo(
        playlist.videos[
          (videoIndex - 1 + playlist.videos.length) % playlist.videos.length
        ]
      );
    } else {
      updateIsPlaying(false);
    }
  }
  function end() {
    if (!playlist?.videos.length || !currentVideo) {
      updateIsPlaying(false);
      return;
    }
    const playlistVideos = playlist.videos;

    if (isShuffle) {
      updateCurrentVideo(
        playlistVideos[getRandomInt(0, playlistVideos.length - 1)]
      );
    } else {
      const videoIndex = playlistVideos.findIndex(
        ({ id: videoId }) => videoId === currentVideo.id
      );
      if (videoIndex !== -1) {
        if (loop === Loop.Single) {
          updateIsPlaying(false);
          updateSeekTo(0);
          setTimeout(() => updateIsPlaying(true), 1000);
        } else if (loop === Loop.Normal) {
          updateCurrentVideo(
            playlistVideos[(videoIndex + 1) % playlistVideos.length]
          );
        } else if (videoIndex < playlistVideos.length - 1) {
          updateCurrentVideo(playlistVideos[videoIndex + 1]);
        } else {
          updateIsPlaying(false);
        }
      } else {
        updateIsPlaying(false);
      }
    }
  }
  return {
    reset,
    updateIsPlaying,
    updateIsShuffle,
    updatePlayed,
    updateSeekTo,
    updateVolume,
    updateLoop,
    toggleLoop,
    play,
    next,
    prev,
    end,
  };
};
