import { useGetUserPlaylistVideos } from "@business-layer/business-logic/lib/bluelyra/hooks/useGetUserPlaylistVideos";
import { Animation } from "@presentational/atoms/animate/Animation";
import { IconButton } from "@presentational/atoms/button/icon-button/IconButton";
import { BluelyraPlaylistVideoCard } from "@presentational/molecules/card/BluelyraPlaylistVideoCard";
import { useLocation, useNavigate } from "react-router-dom";

export const BluelyraVideos = () => {
  const navigate = useNavigate();
  const playlistId = useLocation().pathname.split("/")[3];
  const { currentPlaylist } = useGetUserPlaylistVideos(playlistId);

  return (
    <div className="p-10 w-full h-full flex flex-col">
      {currentPlaylist ? (
        <Animation
          as="form"
          key="bluelyra-playlist-badge"
          animateType="fade__scale--light"
          className="rounded-t-50 rounded-b-25 w-full bg-secondary flex flex-row gap-20 p-5 flex-shrink"
        >
          <img
            src={currentPlaylist.image.format.thumbnail.url}
            alt={currentPlaylist.name}
            className="rounded-20 rounded-tl-45 w-[160px] aspect-video h-auto"
          />
          <div
            className="text-white flex gap-5 justify-start py-5 w-[calc(100%-200px)] cursor-pointer h-fit"
            onClick={() => navigate(-1)}
          >
            <IconButton
              icon="ArrowLeft"
              className="opacity-80 hover:opacity-100 transition-opacity duration-root h-fit"
            />
            <p className="line-clamp-3 text-standard whitespace-normal flex-wrap">
              {currentPlaylist.name}
            </p>
          </div>
        </Animation>
      ) : null}

      {currentPlaylist ? (
        <div className="mt-15 flex-grow overflow-y-auto overflow-x-hidden flex flex-col gap-15 px-15 pb-20">
          {currentPlaylist.videos.map((v, index) => (
            <Animation
              as="form"
              key={v.id}
              animateType="fade__scale--light"
              delay={index * 0.1}
            >
              <BluelyraPlaylistVideoCard {...v} />
            </Animation>
          ))}
        </div>
      ) : null}
    </div>
  );
};
